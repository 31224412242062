<template>
    <div v-if="templateId === '1'" class="signContainer">
        <div class="signParent" :style="'padding-top: '+(boxParentPaddingTop === 0 ? 25 : boxParentPaddingTop)+'px'">
            <div class="signChildWatermark"  :style="'bottom: ' + (boxDataHeight * 0.2) + 'px;'">
                <span class="text-sign">SignOn</span>
                <hr style="margin-top: 0px; margin-bottom: 0px; color: #0dcaf0" />
                <span class="text-sign float-end">SAMPLE01SAMPLE01SAMPLE01SAMPLE01</span>
            </div>
            <div ref="boxData" id="signChildData">
                <div v-for="d in arrData" :key="d" class="dataItem">
                    {{d}}
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="lblTiny text-end text-danger">{{ dataLineCount }} line</div> -->
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
    props: [ 'data', 'templateId'],
    components: { },
    setup (props) {

        const templateId = ref(props.templateId)
        const arrData = ref(props.data)

        const boxParentPaddingTop = ref(25)
        const boxDataHeight = ref(25) // Data section height
        const boxData = ref(null)     // Data section reference
        const dataLineCount = ref(0)  // included linebreak & text wrap - ISSUE: not accurate
        const lineHeight = ref(null)    // line height in px


        const setDataHeight = () => {
            // console.info('- setDataHeight', boxData.value.innerText)
            if (boxData.value) {

                boxDataHeight.value = boxData.value.scrollHeight // offsetHeight inaccurate
                boxParentPaddingTop.value = boxDataHeight.value

                // Calculate the number of lines - not accurate
                dataLineCount.value = Math.floor(boxDataHeight.value / lineHeight.value)
                // console.info('dataLineCount', dataLineCount.value)
            }
        }

        onMounted(() => {
            // console.info('*onMounted', JSON.stringify(arrData.value))
            // FORMULA: line-height (px) = font (px) * line height (ratio) = 14px × 1.2 = 16.8px
            lineHeight.value = 16.8 
            
            // Testing: harcoded data
            /* if (arrData.value.length === 1) {
                arrData.value = ["Lim yen Nee"]
                arrData.value = ["Lim yen Nee Lim yen nee lim yen nee lim yen nee Lim yen nee lim yen nee lim yen nee"]

            } else if (arrData.value.length === 2) {
                arrData.value = ["Lim yen Nee Lim yen nee lim yen nee lim yen nee", "Sinch Genusis consultancy sdn bhd (kl branch)"]
            
            } else if (arrData.value.length === 3) {
                arrData.value = ["Lim yen Nee Lim yen nee lim yen nee lim yen nee", "Sinch Genusis consultancy sdn bhd (kl branch)", "SEA region marketing manager"]

            } else if (arrData.value.length === 4) {
                arrData.value = ["Lim yen Nee Lim yen nee lim yen nee lim yen nee", "Sinch Genusis consultancy sdn bhd (kl branch)", "SEA region marketing manager", "820101-10-1234"]

            } else if (arrData.value.length === 5) {
                arrData.value = ["Lim yen Nee Lim yen nee lim yen nee lim yen nee", "Sinch Genusis consultancy sdn bhd (kl branch)", "SEA region marketing manager", "820101-10-1234", "E1234567890"]
            
            } */

            setTimeout(() => {
                setDataHeight()
            }, 100)
        })

        return {
            templateId, arrData, boxParentPaddingTop, boxDataHeight, boxData, lineHeight, dataLineCount
        }

    }
}
</script>

<style>
    .signContainer {
        border: 1px solid #A0A3A6; 
        padding: 10px;
        width: 300px;
    }

    .signParent {
        position: relative; 
        width: 100%;
        height: auto;
    }

    .signChildWatermark {
        position: absolute;
        left: 0;
        width: 100%;
        /* background-color: papayawhip; */
        z-index: 1;
        opacity: 0.8;
    }

    #signChildData {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        /* background-color: pink; */
        z-index: 0; 
    }
    .dataItem {
        margin-left: 5px; 
        margin-right: 5px; 
        margin-top: 0px; 
        margin-bottom: 0px; 
        padding-bottom: 8px; 
        line-height: 1.2;
    }

    .text-sign {
        color: #0dcaf0;
        font-size: 8px;
        padding: 0px;
        margin: 0px;
    }
</style>
