<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Preferences"/>

    <div class="generalBorderlessBox">
        <div class="row" style="margin-bottom: 45px">
            <!-- Left column -->
            <div class="col-12 col-lg-8 d-grid">
                <div class="generalContentBox border shadow-sm">
                    <!-- Left: Personal -->
                    <div v-if="pageView === 'personal'">
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link" :class="curTab === 'personal' ? 'active' : ''" data-bs-toggle="pill" href="#personal" @click="curTab = 'personal'">Personal Information</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="curTab === 'sign' ? 'active' : ''"  data-bs-toggle="pill" href="#sign" @click="curTab = 'sign'">Signature</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="curTab === 'privacy' ? 'active' : ''"  data-bs-toggle="pill" href="#privacy" @click="curTab = 'privacy'">Privacy Setting</a>
                            </li>
                            <li v-if="getUserStatus === 'ACTIVE'" class="nav-item">
                                <a class="nav-link" data-bs-toggle="pill" href="#password" @click="curTab = 'password'">Password</a>
                            </li>
                            <li v-if="getUserStatus !== 'PENDING'" class="nav-item">
                                <a class="nav-link" data-bs-toggle="pill" href="#kyc" :class="curTab === 'kyc' ? 'active' : ''"  @click="curTab = 'kyc'">KYC</a>
                            </li>
                            
                        </ul>

                        <div class="tab-content mt-4">
                            <div class="tab-pane container" :class="curTab === 'personal' ? 'active' : ''" id="personal">
                                <div class="row">
                                    <div class="col-12 pb-3 text-center">
                                        <table border="0">
                                            <tr>
                                                <td class="pe-4 pt-1">
                                                    <div class="d-flex justify-content-center text-center" style="width: 120px; height: 120px">
                                                        <!-- <img v-if="avatar && avatar.imgBase64" :src="'data:'+ avatar.mimeType +';base64, ' + avatar.imgBase64" class="rounded-circle" align="center" alt="user avatar" style="position:absolute; width: 120px; height: 120px; object-fit: cover" /> -->
                                                        <img v-if="avatar && avatar.imgBase64" :src="avatar.imgBase64" class="rounded-circle" align="center" alt="user avatar" style="position:absolute; width: 120px; height: 120px; object-fit: cover" />
                                                        <fa v-else icon="user" style="position:absolute; width: 116px; height: 116px;" />
                                                        <div class="customLink rounded-circle isLink" data-bs-toggle="modal" data-bs-target="#mdlAvatar">
                                                            <fa icon="camera" class="text-secondary" style="font-size: 32px;"/>
                                                        </div>

                                                        <span v-if="getUserStatus === 'PENDING'" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                                            <fa icon="hourglass-half" class="text-danger" />
                                                        </span>
                                                        <span v-else-if="getUserStatus === 'DISABLED' || getUserStatus === 'EXPIRED' || getUserStatus === 'DELETING' || getUserStatus === 'REACTIVE'" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                                            <fa icon="circle-minus" class="text-danger" />
                                                        </span>
                                                        <span v-else-if="getUserIsVerified === true || getUserIsVerified === 'true'" id="certificate" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                                            <fa icon="circle-check" class="text-success" />
                                                        </span>   
                                                        <span v-if="user && user.jEntity.isOwner === true">
                                                            <Popper class="popperDark" arrow hover content="OWNER" style="z-index: 1001; position: absolute; margin-top: 100px; margin-left: -130px">
                                                                <fa icon="square-pen" class="text-blue" style="z-index: 1000; position: absolute; font-size: 28px; margin-top: -20px; margin-left: 0px" />
                                                            </Popper>
                                                        </span>
                                                        <span v-else-if="user && user.jEntity.isEditor === true">
                                                            <Popper class="popperDark" arrow hover content="EDITOR" style="z-index: 1001; position: absolute; margin-top: 100px; margin-left: -130px">
                                                                <fa icon="square-pen" class="text-blue" style="z-index: 1000; position: absolute; font-size: 28px; margin-top: -20px; margin-left: 0px" />
                                                            </Popper>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="text-start align-top">
                                                    <div>
                                                        <span class="fw-bold">{{ fullLegalName }}</span>
                                                        <span v-if="displayName" class="text-grey">
                                                            <fa icon="at" class="mx-1"/>{{displayName}}
                                                        </span>
                                                    </div>
                                                    <div>{{email}}</div>
                                                    <div><br /></div>
                                                    <div v-if="dateCreate" class="fst-italic">joined {{func.convDateTimeFormat(dateCreate, 'date')}} <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> last login {{ func.getDateTimeDiff(func.convDateTimeFormat(dateLastLogin), new Date()) }}</div>
                                                    <div>
                                                        <span class="badge bg-secondary">{{plan}}</span>
                                                        <span v-if="getUserIsVerified === true || getUserIsVerified === 'true'" class="ms-1 badge bg-success">KYC verified</span>
                                                    </div>
                                                    
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" :disabled="getUserIsVerified === true || getUserIsVerified === 'true'" v-model="fullLegalName" id="fullLegalName" placeholder="Full legal name">
                                            <label for="fullLegalName">Full legal name</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="displayName" id="displayName" placeholder="Display name">
                                            <label for="displayName">Display name</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" :disabled="getUserIsVerified === true || getUserIsVerified === 'true'" class="form-control" v-model="nric" id="nric" placeholder="NRIC">
                                            <label for="nric">NRIC (National Registration Identity)</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div v-if="getUserIsVerified === true || getUserIsVerified === 'true'" class="form-floating">
                                            <div class="text-grey" style="font-size: 12px; font-weight: 400; line-height: 1.5; padding-bottom: 2px;">Date of birth</div>
                                            <!-- <Datepicker :modelValue="dob" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker> -->
                                            <VueDatePicker v-model="dob" model-type="yyyyMMdd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply disabled></VueDatePicker>
                                        </div>
                                        <div v-else class="form-floating">
                                            <div class="text-grey" style="font-size: 12px; font-weight: 400; line-height: 1.5; padding-bottom: 2px;">Date of birth</div>
                                            <!-- <Datepicker :modelValue="dob" @update:modelValue="setDob" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker> -->
                                            <VueDatePicker v-model="dob" model-type="yyyyMMdd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="passport" id="passport" placeholder="Passport" :disabled="getUserIsVerified === true || getUserIsVerified === 'true'">
                                            <label for="passport">Passport</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <div v-if="getUserIsVerified === true || getUserIsVerified === 'true'" class="form-floating">
                                                <div class="text-grey" style="font-size: 12px; font-weight: 400; line-height: 1.5; padding-bottom: 2px;">Passport expiry</div>
                                                <!-- <Datepicker :modelValue="passportExpiry" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker> -->
                                                <VueDatePicker v-model="passportExpiry" model-type="yyyyMMdd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply disabled></VueDatePicker>
                                            </div>
                                            <div v-else class="form-floating">
                                                <div class="text-grey" style="font-size: 12px; font-weight: 400; line-height: 1.5; padding-bottom: 2px;">Passport expiry</div>
                                                <!-- <Datepicker :modelValue="passportExpiry" @update:modelValue="setPassportExpiry" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker> -->
                                                <VueDatePicker v-model="passportExpiry" model-type="yyyyMMdd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <select v-model="passportIssueCountryId" id="passportIssueCountryId" class="form-select" :disabled="getUserIsVerified === true || getUserIsVerified === 'true'">
                                                <option value="">Select Country</option>
                                                <option :value="c.id" v-for="c in countryOpt" :key="c.id">{{c.name}}</option>
                                            </select>
                                            <label for="passportIssueCountryId">Passport country</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <select v-model="gender" id="gender" class="form-select" :disabled="getUserIsVerified === true || getUserIsVerified === 'true'">
                                                <option value="">Select gender</option>
                                                <option :value="g.value" v-for="g in genderOpt" :key="g.value">{{g.label}}</option>
                                            </select>
                                            <label for="gender">Gender</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <select v-model="title" class="form-select" id="title">
                                                <option value="">Select title</option>
                                                <option :value="t.value" v-for="t in titleOpt" :key="t.value">{{ t.label }}</option>
                                            </select>
                                            <label for="title">Title</label>
                                        </div>
                                    </div>

                                    <div v-if="uiStructure === 'PERSONAL'" class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="entityDesignation" id="designation" placeholder="Designation" :disabled="getUserIsVerified === true || getUserIsVerified === 'true'">
                                            <label for="passport">Designation</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-4">
                                        <div class="form-floating">
                                            <select v-model="gmt" id="gmt" class="form-select">
                                                <option value="null">Select GMT</option>
                                                <option :value="g.value" v-for="g in gmtOpt" :key="g.value">{{g.label}}</option>
                                            </select>
                                            <label for="gmt">GMT</label>
                                        </div>
                                    </div>

                                    <div class="col-12 pb-2 fw-bold">Address</div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="address" id="address" placeholder="Street address" :disabled="getUserIsVerified === true || getUserIsVerified === 'true'">
                                            <label for="address">Street address</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="postcode" id="postcode" placeholder="Postcode">
                                            <label for="postcode">Postcode</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="state" id="state" placeholder="State">
                                            <label for="state">State</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-4">
                                        <div class="form-floating">
                                            <select v-model="countryId" id="countryId" class="form-select" :disabled="getUserIsVerified === true || getUserIsVerified === 'true'">
                                                <option value="">Select Country</option>
                                                <option :value="c.id" v-for="c in countryOpt" :key="c.id">{{c.name}}</option>
                                            </select>
                                            <label for="countryId">Country</label>
                                        </div>
                                    </div>

                                    <div class="col-12 pb-2 fw-bold">Others</div>

                                    <div class="col-12 pb-3">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="aboutMe" id="aboutMe" style="height: 120px"></textarea>
                                            <label for="aboutMe">About me</label>
                                        </div>
                                    </div>

                                    <div class="col-12 pb-3">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="workingExp" id="workingExp" style="height: 120px"></textarea>
                                            <label for="workingExp">Working experiences</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="facebook" id="facebook" placeholder="Facebook">
                                            <label for="facebook">Facebook URL</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="twitter" id="twitter" placeholder="twitter">
                                            <label for="twitter">Twitter URL</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 pb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="discord" id="discord" placeholder="discord">
                                            <label for="discord">Discord URL</label>
                                        </div>
                                    </div>

                                    <div v-if="getUserIsVerified === true || getUserIsVerified === 'true'" class="col-12 pb-3 small text-grey">
                                        Note: Verified user not allow to update Full Legal Name, NRIC, Passport, Passport Country, Gender, Address and Country due to his/her identity already approved under SignOn KYC verification.
                                    </div>

                                    <div class="col-12 text-center">
                                        <!-- Large screen -->
                                        <div class="d-none d-md-block">
                                            <table border="0" align="center" >
                                                <tr>
                                                    <td v-if="isSignOn === true && (getUserIsVerified === false || getUserIsVerified === 'false')" class="bg-warning">
                                                        <div class="dropdown me-1 mb-1">
                                                            <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown">
                                                                <fa icon="file-import" size="lg" class="me-2" />Fetch profile from
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlFetchConsent" @click="fetchFr = 'google'"><fa :icon="['fab', 'google']" size="lg" class="me-2" />Google</a></li>
                                                                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlFetchConsent" @click="fetchFr = 'facebook'"><fa :icon="['fab', 'facebook']" size="lg" class="me-2" />Facebook</a></li>
                                                                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlFetchConsent" @click="fetchFr = 'linkedin'"><fa :icon="['fab', 'linkedin']" size="lg" class="me-2" />LinkedIn</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td class="pt-3">
                                                        <button class="btn btn-secondary mb-2" @click="updProfile">
                                                            <fa icon="floppy-disk" size="lg" class="me-2" />Update
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!-- Small screen -->
                                        <div class="text-center d-md-none">
                                            <div class="dropdown me-1 mb-1 bg-warning" v-if="isSignOn === true && (getUserIsVerified === false || getUserIsVerified === 'false')">
                                                <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown">
                                                    <fa icon="file-import" size="lg" class="me-2" />Fetch profile from
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlFetchConsent" @click="fetchFr = 'google'"><fa :icon="['fab', 'google']" size="lg" class="me-2" />Google</a></li>
                                                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlFetchConsent" @click="fetchFr = 'facebook'"><fa :icon="['fab', 'facebook']" size="lg" class="me-2" />Facebook</a></li>
                                                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlFetchConsent" @click="fetchFr = 'linkedin'"><fa :icon="['fab', 'linkedin']" size="lg" class="me-2" />LinkedIn</a></li>
                                                </ul>
                                            </div>
                                        
                                            <button class="btn btn-secondary mb-2" @click="updProfile">
                                                <fa icon="floppy-disk" size="lg" class="me-2" />Update
                                            </button>
                                        </div>
                                    </div>

                                    <div v-if="uiStructure === 'PERSONAL' && (isAdmin === true || isAdmin === 'true')" class="col-12 mb-2"><hr /></div>
                                    <div v-if="uiStructure === 'PERSONAL' && (isAdmin === true || isAdmin === 'true')" class="col-12">
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td width="25%">Structure
                                                        <Popper class="popperDark" arrow hover content="Help">
                                                            <fa icon="circle-question" size="lg" class="isLinkPrimary ms-2" data-bs-toggle="modal" data-bs-target="#mdlRemarkStruture" />
                                                        </Popper>
                                                    </td>
                                                    <td>
                                                        <div class="form-check mb-2">
                                                            <input type="radio" class="form-check-input" v-model="uiStructure" value="PERSONAL" :disabled="(isAdmin === false || isAdmin === 'false') || uiStructure === 'WHITELABEL'" :checked="uiStructure === 'PERSONAL'" data-bs-toggle="modal" data-bs-target="#mdlEditStruture" @change="newUiStructure = 'PERSONAL'">Personal
                                                            <label class="form-check-label" for="radio1"></label>
                                                            <div class="fst-italic text-grey">Hide unnecessary team features</div>
                                                        </div>
                                                        <div class="form-check mb-2">
                                                            <input type="radio" class="form-check-input" v-model="uiStructure" value="TEAM" :disabled="(isAdmin === false || isAdmin === 'false') || uiStructure === 'WHITELABEL'" :checked="uiStructure === 'TEAM'" data-bs-toggle="modal" data-bs-target="#mdlEditStruture" @change="newUiStructure = 'TEAM'">Team
                                                            <label class="form-check-label" for="radio1"></label>
                                                            <div class="fst-italic text-grey">Show all team features</div>
                                                        </div>
                                                        <div v-if="isSignOn === true" class="form-check bg-warning">
                                                            <input type="radio" class="form-check-input" v-model="uiStructure" value="WHITELABEL" :disabled="(isAdmin === false || isAdmin === 'false')" :checked="uiStructure === 'WHITELABEL'" data-bs-toggle="modal" data-bs-target="#mdlEditStruture" @change="newUiStructure = 'WHITELABEL'">Whitelabel
                                                            <label class="form-check-label" for="radio1"></label>
                                                            <div class="fst-italic text-grey">Show whitelabel features</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="tab-pane container" :class="curTab === 'sign' ? 'active' : 'fade'" id="sign">
                                <div class="row">
                                    <div class="col-12 pb-3">You may pre-upload your full signature, initial and stamp for a smoother digital signing experience. </div>
                                    <div class="col-12 pb-4">
                                        <div class="isLinkPrimary mb-2" @click="showSignRemark = !showSignRemark"><fa icon="circle-question" size="lg" class="me-2" />How to capture a good looking signature?</div>
                                        <div v-if="showSignRemark" class="pb-2">
                                            You may upload signature with transparent background for the best result. <br/>
                                            Alternatively, sign your signature on a piece of white paper, then scan and crop the image. Use a thicker pen for the best result.
                                        </div>
                                    </div>
                                </div>

                                <div v-if="isSignOn === true" class="row mb-4 bg-warning">
                                    <div class="col-12 mb-2">
                                        <div class="fw-bold">Template</div>
                                        <div class="text-grey">The template you have chosen below will be applied to all your signatures and stamps</div>
                                    </div>

                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-4 mb-2">
                                                <div class="w-100 h-100 isLink divHover divSelected" style="display: grid; place-items: left; height: 187px; position: relative">
                                                    <span class="text-info" style="position: absolute; z-index: 0; top: 3px; right: 5px"><fa icon="check" /></span>
                                                    <img src="./../../assets/template/template1_96.png" class="img-responsive" style="width: 100%"/>
                                                    <div :style="'font-size: 4pt; font-family: courier; padding-left: 2px'" class="text-info">
                                                        <div>{{ dateSignIdSample.replace(/(.{4})/g, '$1 ').trim() }}</div>
                                                        <div>{{ func.convDateTimeFormat(new Date(), 'datetimegmt') }}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 mb-2">
                                                <div class="w-100 h-100 isLink divHover" style="display: grid; place-items: left; height: 500px; position: relative">
                                                    <!-- <span class="text-info" style="position: absolute; z-index: 0; top: 3px; right: 5px"><fa icon="check" /></span> -->
                                                    <img src="./../../assets/template/template2_96.png" class="img-responsive" style="width: 100%"/>
                                                    <div :style="'font-size: 4pt; font-family: courier; padding-left: 2px'" class="text-info">
                                                        <div>{{ dateSignIdSample.replace(/(.{4})/g, '$1 ').trim() }}</div>
                                                        <div>{{ func.convDateTimeFormat(new Date(), 'datetimegmt') }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-3 mb-0"><hr /></div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col-12 mb-2">
                                        <span class="fw-bold">Signature</span>
                                        <span class="text-primary isLink float-end" data-bs-toggle="modal" data-bs-target="#mdlAddSig">
                                            <fa icon="plus" class="me-2" />Add
                                        </span>
                                    </div>

                                    <div class="col-12">
                                        <div class="row">
                                            <div v-for="s in arrSig.slice(0, 3)" :key="s.signatureId" class="col-lg-4">
                                                <div class="d-grid" style="height: 100%">
                                                    <table class="table table-borderless isLink bg-uploaded-img">
                                                        <tr>
                                                            <td class="p-2">
                                                                <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="signature" />
                                                            </td>
                                                            <td class="text-end align-top pe-1" width="40px">
                                                                <Popper class="popperDark" arrow hover content="Delete">
                                                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                                                </Popper>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                            <div v-if="showMoreSig" v-for="s in arrSig.slice(3)" :key="s.signatureId" class="col-lg-4">
                                                <div class="d-grid" style="height: 100%">
                                                    <table class="table table-borderless isLink bg-uploaded-img">
                                                        <tr>
                                                            <td class="p-2">
                                                                <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="signature" />
                                                            </td>
                                                            <td class="text-end align-top pe-1" width="40px">
                                                                <Popper class="popperDark" arrow hover content="Delete">
                                                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                                                </Popper>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                            <div v-if="arrSig.length > 3" class="col-12 text-end text-primary mt-0">
                                                <span @click="showMoreSig = !showMoreSig" class="isLink">
                                                    <span v-if="!showMoreSig"><fa icon="angle-down" /> more</span>
                                                    <span v-if="showMoreSig"><fa icon="angle-up" /> less</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col-12 mb-2">
                                        <span class="fw-bold">Initial</span>
                                        <span class="text-primary isLink float-end" data-bs-toggle="modal" data-bs-target="#mdlAddIni">
                                            <fa icon="plus" class="me-2" />Add
                                        </span>
                                    </div>

                                    <div class="col-12">
                                        <div class="row">
                                            <div v-for="s in arrIni.slice(0, 3)" :key="s.signatureId" class="col-lg-4">
                                                <div class="d-grid" style="height: 100%">
                                                    <table class="table table-borderless isLink bg-uploaded-img">
                                                        <tr>
                                                            <td class="p-2">
                                                                <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="initial" />
                                                            </td>
                                                            <td class="text-end align-top pe-1" width="40px">
                                                                <Popper class="popperDark" arrow hover content="Delete">
                                                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'initial')"/>
                                                                </Popper>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                            <div v-if="showMoreIni" v-for="s in arrIni.slice(3)" :key="s.signatureId" class="col-lg-4">
                                                <div class="d-grid" style="height: 100%">
                                                    <table class="table table-borderless isLink bg-uploaded-img">
                                                        <tr>
                                                            <td class="p-2">
                                                                <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="initial" />
                                                            </td>
                                                            <td class="text-end align-top pe-1" width="40px">
                                                                <Popper class="popperDark" arrow hover content="Delete">
                                                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'initial')"/>
                                                                </Popper>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                            <div v-if="arrIni.length > 3" class="col-12 text-end text-primary mt-0">
                                                <span @click="showMoreIni = !showMoreIni" class="isLink">
                                                    <span v-if="!showMoreIni"><fa icon="angle-down" /> more</span>
                                                    <span v-if="showMoreIni"><fa icon="angle-up" /> less</span>
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col-12 mb-2">
                                        <span class="fw-bold">Stamp</span>
                                        <span class="text-primary isLink float-end" data-bs-toggle="modal" data-bs-target="#mdlAddSta">
                                            <fa icon="plus" class="me-2" />Add
                                        </span>
                                    </div>

                                    <div class="col-12">
                                        <div class="row">
                                            <div v-for="s in arrSta.slice(0, 3)" :key="s.signatureId" class="col-lg-4">
                                                <div class="d-grid" style="height: 100%">
                                                    <table class="table table-borderless isLink bg-uploaded-img">
                                                        <tr>
                                                            <td class="p-2">
                                                                <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="stamp" />
                                                            </td>
                                                            <td class="text-end align-top pe-1" width="40px">
                                                                <Popper class="popperDark" arrow hover content="Delete">
                                                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'stamp')"/>
                                                                </Popper>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                            <div v-if="showMoreSta" v-for="s in arrSta.slice(3)" :key="s.signatureId" class="col-lg-4">
                                                <div class="d-grid" style="height: 100%">
                                                    <table class="table table-borderless isLink bg-uploaded-img">
                                                        <tr>
                                                            <td class="p-2">
                                                                <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="stamp" />
                                                            </td>
                                                            <td class="text-end align-top pe-1" width="40px">
                                                                <Popper class="popperDark" arrow hover content="Delete">
                                                                    <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'stamp')"/>
                                                                </Popper>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                            <div v-if="arrSta.length > 3" class="col-12 text-end text-primary mt-0">
                                                <span @click="showMoreSta = !showMoreSta" class="isLink">
                                                    <span v-if="!showMoreSta"><fa icon="angle-down" /> more</span>
                                                    <span v-if="showMoreSta"><fa icon="angle-up" /> less</span>
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button type="button" class="btn btn-secondary mb-2 ms-2" data-bs-toggle="modal" data-bs-target="#mdlComboPreview" @click="showComboPreview">
                                            <fa icon="signature" size="lg" class="me-2" />Preview
                                        </button>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="tab-pane container fade" id="privacy">
                                <div class="row">
                                    <div class="col-12 pb-3">To show what personal information will be disclosed to the public</div>
                                    <div class="col-12">
                                        <table class="table table-sm table-borderless" border="0">
                                            <tbody>
                                                <tr>
                                                    <td width="50px">
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox" v-model="showDisplayName" value="true" disabled>
                                                        </div>
                                                    </td>
                                                    <td>Display name</td>
                                                </tr>

                                                <tr>
                                                    <td width="50px">
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox" v-model="showFullLegalName" value="true" disabled>
                                                        </div>
                                                    </td>
                                                    <td>Full legal name</td>
                                                </tr>

                                                <tr>
                                                    <td width="50px">
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox" v-model="showAboutMe" value="true">
                                                        </div>
                                                    </td>
                                                    <td>About me</td>
                                                </tr>

                                                <tr>
                                                    <td width="50px">
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox" v-model="showWorkingExp" value="true">
                                                        </div>
                                                    </td>
                                                    <td>Working Experiences</td>
                                                </tr>

                                                <tr>
                                                    <td width="50px">
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox" v-model="showOrganization" value="true">
                                                        </div>
                                                    </td>
                                                    <td>My Organization</td>
                                                </tr>

                                            </tbody>    
                                        </table>
                                    </div>

                                    <div class="col-12 text-center">
                                        <button class="btn btn-secondary mb-1" @click="updPrivacy">
                                            <fa icon="floppy-disk" size="lg" class="me-2" />Update
                                        </button>
                                    </div> 
                                </div>
                            </div>

                            <div class="tab-pane container fade" id="password">
                                <div class="row">
                                    <div class="col-12 pb-3">For security reason, please key in your current password to confirm your account before you set a new password</div>
                                    <div class="col-12 col-lg-6 pb-3">
                                        <input v-model="oldPassword" type="password" class="form-control" placeholder="Current password">
                                    </div>

                                    <div class="col-12 col-lg-6 d-none d-md-block">
                                        
                                    </div>

                                    <div v-if="oldPassword && oldPassword.length > 0" class="col-12 col-lg-6 pb-3">
                                        <input v-model="password" type="password" class="form-control" placeholder="New password" @input="valPassword">
                                        <div class="mt-1">
                                            <div v-if="!pwd_length"><fa icon="circle-exclamation" class="text-danger pe-2" />At least 11 characters</div>
                                            <div v-if="!pwd_number"><fa icon="circle-exclamation" class="text-danger pe-2" />Minimum 1 numeric digit</div>
                                            <div v-if="!pwd_lowercase"><fa icon="circle-exclamation" class="text-danger pe-2" />Minimum 1 lowercase letter</div>
                                            <div v-if="!pwd_uppercase"><fa icon="circle-exclamation" class="text-danger pe-2" />Minimum 1 capital letter</div>
                                            <div v-if="!pwd_special"><fa icon="circle-exclamation" class="text-danger pe-2" />Minimum 1 special character</div>
                                        </div>
                                    </div>

                                    <div v-if="(oldPassword && oldPassword.length > 0 && password2 && password2.length > 0) || passwordFormat " class="col-12 col-lg-6 pb-3">
                                        <input v-model="password2" type="password" class="form-control" placeholder="Reconfirm new password" @input="valPasswordMatch">
                                        <div v-if="password2 && password2.length > 0 && !passwordMatch" class="mt-1">
                                            <fa icon="circle-exclamation" class="text-danger pe-2" />Password not match
                                        </div>
                                    </div>

                                    <div class="col-12 pt-3 pb-0 text-center">
                                        <button v-if="getUserStatus === 'ACTIVE' && passwordFormat && passwordMatch" class="btn btn-secondary" @click="updPassword">
                                            <fa icon="floppy-disk" size="lg" class="me-2" />Update
                                        </button>
                                        <button v-if="getUserStatus !== 'ACTIVE'" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlUpdProfile">
                                            <fa icon="floppy-disk" size="lg" class="me-2" />Update
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div  class="tab-pane container" :class="curTab === 'kyc' ? 'active' : 'fade'" id="kyc">
                                <div v-if="getUserIsVerified === 'false' || getUserIsVerified === false" class="row">
                                    <div v-if="userKycStatus === 'PENDING'" class="col-12 pb-3">
                                        Complete KYC verification by upload front and back of your NRIC or Passport to have more access in the system.
                                    </div>
                                    <div v-else-if="userKycStatus === 'SUBMITTED'" class="col-12 pb-3">
                                        We have received your KYC verification request. <br />Kindly allow some time for us to process accordingly.
                                    </div>
                                    <div v-else-if="userKycStatus === 'PROCESS'" class="col-12 pb-3">
                                        Your KYC verification request is in progress. <br />Kindly allow us some time to verify your information and documents.
                                    </div>
                                    <div v-else-if="userKycStatus === 'REJECTED'" class="col-12 pb-3">
                                        <div>We are sorry to inform your that your KYC verification is failed.</div>
                                        <div v-if="userKycRemark" class="mb-3">Reason: {{ userKycRemark }}</div>
                                        <div>You may review and update your personal information then re-upload your NRIC/Passport.</div>
                                    </div>
                                    
                                    <div v-if="allowKyc === false" class="col-12 pb-3">
                                        <div class="pb-2">Following data is compulsory for KYC verification, kindly update in 'Personal Information'</div>
                                        <div v-if="fullLegalName === '' || fullLegalName === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing full legal name</div>
                                        <div v-if="gender === '' || gender === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing gender</div>
                                        <div v-if="dob === '' || dob === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing date of birth</div>
                                        <div v-if="address === '' || address === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing address</div>
                                        <div v-if="countryId === '' || countryId === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing country</div>
                                        <div v-if="(nric === '' || nric === undefined) && (passport === '' || passport === undefined)"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing NRIC / passport</div>
                                        <div v-else-if="passport !== '' && passport !== undfined && (passportExpiry === '' || passportExpiry === undefined)"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing passport expiry</div>
                                        <div v-else-if="passport !== '' && passport !== undfined && (passportIssueCountryId === '' || passportIssueCountryId === undefined)"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing passport country</div>
                                    </div>

                                    <div v-if="allowKyc === true && (userKycStatus === 'PENDING' || userKycStatus === 'REJECTED')" class="col-12 pb-3">
                                        <div class="card">
                                            <div class="card-header">
                                                <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#kycNric" style="width: 100%" @click="showKycNric = !showKycNric">NRIC
                                                    <span class="float-end" :hidden="showKycNric === false"><fa icon="chevron-up" /></span>
                                                    <span class="float-end" :hidden="showKycNric === true"><fa icon="chevron-down" /></span>
                                                </a>
                                            </div>

                                            <div id="kycNric" class="collapse show p-3" >
                                                <div class="row">
                                                    <div v-if="getUserIsVerified === false || getUserIsVerified === 'false'" class="col-lg-6 col-12 pb-3">
                                                        <select v-model="kyc" class="form-select" @change="getKYCConfig()">
                                                            <option value="null">Select Type</option>
                                                            <option :value="k.value" v-for="k in kycOpt" :key="k.value">{{k.label}}</option>
                                                        </select>
                                                    </div>
                                                    <div v-if="getUserIsVerified === false || getUserIsVerified === 'false'" class="col-12">
                                                        <file-pond
                                                            name="file"
                                                            ref="pondKyc"
                                                            label-idle="Drop files"
                                                            v-bind:allow-multiple="true"
                                                            accepted-file-types="image/jpg, image/jpeg, image/png, image/gif, application/pdf"
                                                            :server="kycConfig"
                                                            :files="myKyc"
                                                            storeAsFile="true"
                                                            @init="handleKycUploadInit"
                                                            @error="uplKycError"
                                                            @processfile="onprocessKyc"
                                                            @addfile="getKyc"
                                                            allowRevert="true"
                                                            allowRemove="false"
                                                        />
                                                        <div class="text-center small text-grey">Max file size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF, PDF only</div>
                                                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Please ensure all 4 corners are visible</div>
                                                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Text is clearly readable without blockage or blurry</div>
                                                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Images in original color, not black & white or changed in any way</div>
                                                    </div>

                                                    <div v-if="getUserIsVerified === false || getUserIsVerified === 'false'" class="col-12"><hr /></div>
                                                    <div class="col-12">
                                                        <div v-for="(k, kIdx) in arrKycNric" :key="k.id">
                                                            <table class="table table-borderless">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <span v-if="k.type === 'frontMyKad'">NRIC Front</span>
                                                                        <span v-if="k.type === 'backMyKad'">NRIC Back</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td v-if="k.mimeType === 'application/pdf'" width="95%">
                                                                        <span class="isLink" @click="downloadNricKyc(k.dlUrl)"><fa icon="file-pdf" class="me-2" />Download</span>
                                                                    </td>
                                                                    <td v-else width="95%">
                                                                        <ImgKyc :id="k.id" :width="k.width" :height="k.height" label="kyc" />
                                                                        <!-- <img :src="'data:image/png;base64, ' + k.imgBase64" style="padding: 0px;" class="img-fluid" alt="kyc" /> -->
                                                                    </td>
                                                                    <td v-if="getUserIsVerified === false || getUserIsVerified === 'false'" class="text-end">
                                                                        <Popper class="popperDark" arrow hover content="Delete image">
                                                                            <fa icon="trash" size="lg" class="isLink" @click.prevent="delKyc(k.id)"/>
                                                                        </Popper>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <hr v-if="kIdx < arrKycNric.length-1" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div><!-- kycNric End -->
                                        </div>
                                    </div>

                                    <div v-if="allowKyc === true && (userKycStatus === 'PENDING' || userKycStatus === 'REJECTED')" class="col-12 pb-3">
                                        <div class="card">
                                            <div class="card-header">
                                                <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#kycPassport" style="width: 100%" @click="showKycPassport = !showKycPassport">Passport
                                                    <span class="float-end" :hidden="showKycPassport === false"><fa icon="chevron-up" /></span>
                                                    <span class="float-end" :hidden="showKycPassport === true"><fa icon="chevron-down" /></span>
                                                </a>
                                            </div>

                                            <div id="kycPassport" class="collapse show p-3" >
                                                 <div class="row">
                                                    <div class="col-12">
                                                        <file-pond
                                                            name="file"
                                                            ref="pondKyc2"
                                                            label-idle="Drop files"
                                                            v-bind:allow-multiple="true"
                                                            accepted-file-types="image/jpg, image/jpeg, image/png, image/gif, application/pdf"
                                                            :server="kyc2Config"
                                                            :files="myKyc2"
                                                            storeAsFile="true"
                                                            @init="handleKyc2UploadInit"
                                                            @error="uplKyc2Error"
                                                            @processfile="onprocessKyc2"
                                                            @addfile="getKyc2"
                                                            allowRevert="true"
                                                            allowRemove="false"
                                                        />
                                                        <div class="text-center small text-grey">Max file size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF, PDF only</div>
                                                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Please ensure all 4 corners are visible</div>
                                                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Text is clearly readable without blockage or blurry</div>
                                                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Images in original color, not black & white or changed in any way</div>
                                                    </div>

                                                    <div class="col-12"><hr /></div>
                                                    <div class="col-12">
                                                        <div v-for="(k, kIdx) in arrKycPassport" :key="k.id">
                                                            <table class="table table-borderless">
                                                                <tr>
                                                                    <td v-if="k.mimeType === 'application/pdf'" width="95%">
                                                                        <span class="isLink" @click="downloadPassportKyc(k.dlUrl)"><fa icon="file-pdf" class="me-2" />Download</span>
                                                                    </td>
                                                                    <td v-else width="95%">
                                                                        <ImgKyc :id="k.id" :width="k.width" :height="k.height" label="kyc" />
                                                                        <!-- <img :src="'data:image/png;base64, ' + k.imgBase64" style="padding: 0px;" class="img-fluid" alt="kyc" /> -->
                                                                    </td>
                                                                    <td v-if="getUserIsVerified === false || getUserIsVerified === 'false'" class="text-end">
                                                                        <Popper class="popperDark" arrow hover content="Delete image">
                                                                            <fa icon="trash" size="lg" class="isLink" @click.prevent="delKyc(k.id)"/>
                                                                        </Popper>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <hr v-if="kIdx < arrKycPassport.length-1" />
                                                        </div>
                                                    </div>
                                                 </div>
                                            </div><!-- kycPassport End -->
                                        </div>
                                    </div>

                                </div>

                                <div v-else class="row">
                                    You are KYC verified user, your identity is authenticated and verified by our system.
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Left: Entity -->
                    <div v-if="pageView === 'entity'">
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link" :class="curTab === 'entity' ? 'active' : ''" data-bs-toggle="pill" href="#entity" @click="curTab = 'entity'">Organization Information</a>
                            </li>
                            <li v-if="isSignOn === true && user.jEntity.isFinance === true" class="nav-item bg-warning">
                                <a class="nav-link" :class="curTab === 'billing' ? 'active' : ''" data-bs-toggle="pill" href="#billing" @click="curTab = 'billing'">Billing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="pill" href="#entitykyc" @click="curTab = 'entitykyc'">KYC</a>
                            </li>
                        </ul>

                        <div class="tab-content mt-4">
                            <div class="tab-pane container" :class="curTab === 'entity' ? 'active' : ''" id="entity">
                                <div class="row">
                                    <div class="col-12 pb-3 text-center">
                                        <table border="0">
                                            <tr>
                                                <td class="pe-4 pt-1" width="125px" >

                                                    <div v-if="objEntity && objEntity.entityId" style="width: 120px">
                                                        <ImgLogo :id="objEntity.jLogo.entityId" :isVerified="objEntity.isVerified" :width="objEntity.jLogo.width" :height="objEntity.jLogo.height" />
                                                        <div v-if="isAdmin === true" :style="'margin-top: ' + objEntity.jLogo.height*-1 + 'px; height: '+objEntity.jLogo.height+'px'" class="customLink2 isLink"  data-bs-toggle="modal" data-bs-target="#mdlEntityUpload">
                                                            <div :style="'margin-top: '+ cameraGap +'px'" class="customLink2">
                                                                <fa icon="camera" class="text-secondary" style="font-size: 32px" />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                   
                                                </td>
                                                <td class="text-start align-top">
                                                    <div>
                                                        <span class="fw-bold">{{ objEntity.entityName }}</span>
                                                    </div>
                                                    <span v-if="objEntity.isVerified === true || objEntity.isVerified === 'true'" class="badge bg-success">KYC verified</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div v-if="isAdmin === true || isAdmin === 'true'" class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityName" id="entityName" placeholder="Name of organization" :disabled="isEntityVerified === true || isEntityVerified === 'true'">
                                                    <label for="entityName">Name of organization</label>
                                                </div>
                                            </div>

                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityRegID" id="entityRegID" placeholder="Registration ID" :disabled="isEntityVerified === true || isEntityVerified === 'true'">
                                                    <label for="entityRegID">Registration ID</label>
                                                </div>
                                            </div>
                                            
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <select v-model="entityCountry" class="form-select" id="entityCountry">
                                                        <option value="">Select country</option>
                                                        <option :value="c.id" v-for="c in countryOpt" :key="c.id">{{ c.name }}</option>
                                                    </select>
                                                    <label for="entityCountry">Country</label>
                                                </div>
                                            </div>

                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityUrl" id="entityUrl" placeholder="Website">
                                                    <label for="entityUrl">Website</label>
                                                </div>
                                            </div>

                                            <div class="col-12 pb-2 fw-bold">Others</div>
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityFacebook" id="entityFacebook" placeholder="Facebook">
                                                    <label for="entityFacebook">Facebook URL</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityTwitter" id="entityTwitter" placeholder="twitter">
                                                    <label for="entityTwitter">Twitter URL</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityDiscord" id="entityDiscord" placeholder="discord">
                                                    <label for="entityDiscord">Discord URL</label>
                                                </div>
                                            </div>

                                            <div v-if="isAdmin === true || isAdmin === 'true'" class="col-12 pt-3 text-center">
                                                <button type="button" @click="updEntity" class="btn btn-secondary">
                                                    <fa icon="floppy-disk" size="lg" class="me-2" />Update
                                                </button>
                                            </div>

                                            <div class="col-12 mt-3 mb-3"><hr /></div>

                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityDesignation" id="entityDesignation" placeholder="Designation">
                                                    <label for="entityDesignation">Designation</label>
                                                </div>
                                            </div>

                                            <div class="col-12 pt-3 text-center">
                                                <button type="button" @click="updDesignation" class="btn btn-secondary me-2 mb-2">
                                                    <fa icon="floppy-disk" size="lg" class="me-2" />Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityDesignation" id="entityDesignation" placeholder="Designation">
                                                    <label for="entityDesignation">Designation</label>
                                                </div>
                                            </div>

                                            <div class="col-12 pt-3 text-center">
                                                <button type="button" @click="updDesignation" class="btn btn-secondary me-2 mb-2">
                                                    <fa icon="floppy-disk" size="lg" class="me-2" />Update
                                                </button>
                                            </div>

                                            <div class="col-12 mt-3 mb-3"><hr /></div>

                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityName" id="entityName" :disabled="true" placeholder="Name of organization">
                                                    <label for="entityName">Name of organization</label>
                                                </div>
                                            </div>
                                            
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <select v-model="entityCountry" :disabled="true" class="form-select" id="entityCountry">
                                                        <option value="">Select country</option>
                                                        <option :value="c.id" v-for="c in countryOpt" :key="c.id">{{ c.name }}</option>
                                                    </select>
                                                    <label for="entityCountry">Country</label>
                                                </div>
                                            </div>

                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityUrl" id="entityUrl" :disabled="true" placeholder="Website">
                                                    <label for="entityName">Website</label>
                                                </div>
                                            </div>

                                            <div class="col-12 pb-2 fw-bold">Others</div>
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityFacebook" id="entityFacebook" :disabled="true" placeholder="Facebook">
                                                    <label for="entityFacebook">Facebook URL</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityTwitter" id="entityTwitter" :disabled="true" placeholder="twitter">
                                                    <label for="entityTwitter">Twitter URL</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6 pb-3">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" v-model="entityDiscord" id="entityDiscord" :disabled="true" placeholder="discord">
                                                    <label for="entityDiscord">Discord URL</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div v-if="isAdmin === true || isAdmin === 'true'" class="col-12 mb-2"><hr /></div>
                                    <div v-if="isAdmin === true || isAdmin === 'true'" class="col-12">
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td width="25%">Structure
                                                        <Popper class="popperDark" arrow hover content="Help">
                                                            <fa icon="circle-question" size="lg" class="isLinkPrimary ms-2" data-bs-toggle="modal" data-bs-target="#mdlRemarkStruture" />
                                                        </Popper>
                                                    </td>
                                                    <td>
                                                        <div class="form-check mb-2">
                                                            <input type="radio" class="form-check-input" v-model="uiStructure" value="PERSONAL" :disabled="(isAdmin === false || isAdmin === 'false') || uiStructure === 'WHITELABEL' || (uiStructure === 'TEAM' && entityTotalMember > 1)" :checked="uiStructure === 'PERSONAL'" data-bs-toggle="modal" data-bs-target="#mdlEditStruture" @change="newUiStructure = 'PERSONAL'">Personal
                                                            <label class="form-check-label" for="radio1"></label>
                                                            <div class="fst-italic text-grey">Hide unnecessary team features</div>
                                                        </div>
                                                        <div class="form-check mb-2">
                                                            <input type="radio" class="form-check-input" v-model="uiStructure" value="TEAM" :disabled="(isAdmin === false || isAdmin === 'false') || uiStructure === 'WHITELABEL'" :checked="uiStructure === 'TEAM'" data-bs-toggle="modal" data-bs-target="#mdlEditStruture" @change="newUiStructure = 'TEAM'">Team
                                                            <label class="form-check-label" for="radio1"></label>
                                                            <div class="fst-italic text-grey">Show all team features</div>
                                                        </div>
                                                        <div v-if="isSignOn === true" class="form-check bg-warning">
                                                            <input type="radio" class="form-check-input" v-model="uiStructure" value="WHITELABEL" :disabled="(isAdmin === false || isAdmin === 'false')" :checked="uiStructure === 'WHITELABEL'" data-bs-toggle="modal" data-bs-target="#mdlEditStruture" @change="newUiStructure = 'WHITELABEL'">Whitelabel
                                                            <label class="form-check-label" for="radio1"></label>
                                                            <div class="fst-italic text-grey">Show whitelabel features</div>
                                                        </div>
                                                    
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                   
                                </div>
                            </div>
                            
                            <div v-if="user.jEntity.isFinance === true" class="tab-pane container" :class="curTab === 'billing' ? 'active' : 'fade'" id="billing">
                                <div class="row">
                                    <div class="col-12 mb-2 fw-bold">Subscription</div>

                                    <div class="col-12 mb-2">
                                        <table>
                                            <tr>
                                                <td class="pb-2" :class="payInYear === true ? '' : 'text-primary'">Pay Monthly</td>
                                                <td class="ps-3 pe-2">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" v-model="payInYear" value="true">
                                                    </div>
                                                </td>
                                                <td class="pb-2" :class="payInYear === true ? 'text-primary' : ''">Pay Yearly</td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-lg-6 d-grid">
                                                <div class="packageBox border shadow-sm">
                                                    <div class="fw-bold text-center mb-1">
                                                        <span>Free</span>
                                                    </div>
                                                    <div class="text-center">
                                                        <span class="fs-1 ps-1">FOC</span>
                                                    </div>
                                                    <div class="text-center"><hr /></div>
                                                    <div class="mb-2">
                                                        <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">2</span> credits / user
                                                    </div>
                                                    <div class="mb-2">
                                                        <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">100MB</span> storage / user
                                                    </div>
                                                    <div class="text-center pt-4">
                                                        <button class="btn btn-secondary" @click="$router.push({ path: '/auth/preference/upgrade/personal' })">
                                                            <fa icon="hand-pointer" class="me-2" />Get started
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-lg-6 d-grid">
                                                <div class="packageBox border shadow-sm">
                                                    <div class="fw-bold text-center mb-1">
                                                        <span>Plan 30</span>
                                                    </div>
                                                    <div class="text-center">
                                                        <span class="fw-bold text-uppercase">USD</span>
                                                        <span v-if="payInYear === false" class="fs-1 ps-1">5</span>
                                                        <span v-if="payInYear === true" class="fs-1 ps-1">55</span>
                                                        <span v-if="payInYear === false"> / month</span>
                                                        <span v-if="payInYear === true"> / year</span>
                                                    </div>
                                                    <div class="text-center"><hr /></div>
                                                    <div class="mb-2">
                                                        <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">30</span> credits / user
                                                    </div>
                                                    <div class="mb-2">
                                                        <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">1GB</span> storage / user
                                                    </div>
                                                    <div class="text-center pt-4">
                                                        <button class="btn btn-secondary" @click="$router.push({ path: '/auth/preference/upgrade/personal' })">
                                                            <fa icon="hand-pointer" class="me-2" />Get started
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-lg-6 d-grid">
                                                <div class="packageBox border shadow-sm">
                                                    <div class="fw-bold text-center mb-1">
                                                        <span>Plan 300</span>
                                                    </div>
                                                    <div class="text-center">
                                                        <span class="fw-bold text-uppercase">USD</span>
                                                        <span v-if="payInYear === false" class="fs-1 ps-1">42</span>
                                                        <span v-if="payInYear === true" class="fs-1 ps-1">464</span>
                                                        <span v-if="payInYear === false"> / month</span>
                                                        <span v-if="payInYear === true"> / year</span>
                                                    </div>
                                                    <div class="text-center"><hr /></div>
                                                    <div class="mb-2">
                                                        <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">300</span> credits / user
                                                    </div>
                                                    <div class="mb-2">
                                                        <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">5GB</span> storage / user
                                                    </div>
                                                    <div class="text-center pt-4">
                                                        <button class="btn btn-secondary" @click="$router.push({ path: '/auth/preference/upgrade/personal' })">
                                                            <fa icon="hand-pointer" class="me-2" />Get started
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-lg-6 d-grid">
                                                <div class="packageBox border shadow-sm">
                                                    <div class="fw-bold text-center mb-1">
                                                        <span>Plan 1000</span>
                                                    </div>
                                                    <div class="text-center">
                                                        <span class="fw-bold text-uppercase">USD</span>
                                                        <span v-if="payInYear === false" class="fs-1 ps-1">120</span>
                                                        <span v-if="payInYear === true" class="fs-1 ps-1">1,325</span>
                                                        <span v-if="payInYear === false"> / month</span>
                                                        <span v-if="payInYear === true"> / year</span>
                                                    </div>
                                                    <div class="text-center"><hr /></div>
                                                    <div class="mb-2">
                                                        <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">1,000</span> credits / user
                                                    </div>
                                                    <div class="mb-2">
                                                        <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">30GB</span> storage / user
                                                    </div>
                                                    <div class="text-center pt-4">
                                                        <button class="btn btn-secondary" @click="$router.push({ path: '/auth/preference/upgrade/personal' })">
                                                            <fa icon="hand-pointer" class="me-2" />Get started
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 fst-italic mb-3">
                                                <div>* FREE one-time 10 credits upon successful activation</div>
                                                <div>* FREE one-time 10 credits upon successful KYC</div>
                                            </div>

                                            <div class="col-12 mb-3"><hr /></div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-lg-6">
                                                <div class="row">
                                                    <div class="col-12 mb-3">Need more credit / storage for user ?</div>
                                                    <div class="col-12 mb-2">
                                                        <div class="dropdown">
                                                            <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown">
                                                                Extra credit for user
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#"><b>15</b> Credits - <span v-if="payInYear === false">USD 3 / month</span><span  v-if="payInYear === true">USD 33 / year</span></a></li>
                                                                <li><a class="dropdown-item" href="#"><b>50</b> Credits - <span v-if="payInYear === false">USD 9 / month</span><span  v-if="payInYear === true">USD 99 / year</span></a></li>
                                                                <li><a class="dropdown-item" href="#"><b>150</b> Credits - <span v-if="payInYear === false">USD 26 / month</span><span  v-if="payInYear === true">USD 287 / year</span></a></li>
                                                                <li><a class="dropdown-item" href="#"><b>500</b> Credits - <span v-if="payInYear === false">USD 85 / month</span><span  v-if="payInYear === true">USD 938 / year</span></a></li>
                                                            </ul>
                                                        </div> 
                                                    </div> 

                                                    <div class="col-12 mb-3">
                                                        <div class="dropdown">
                                                            <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown">
                                                                Extra storage for user
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#"><b>5</b> GB - <span v-if="payInYear === false">USD 2 / month</span><span  v-if="payInYear === true">USD 22 / year</span></a></li>
                                                                <li><a class="dropdown-item" href="#"><b>50</b> GB - <span v-if="payInYear === false">USD 16 / month</span><span  v-if="payInYear === true">USD 177 / year</span></a></li>
                                                                <li><a class="dropdown-item" href="#"><b>200</b> GB - <span v-if="payInYear === false">USD 60 / month</span><span  v-if="payInYear === true">USD 662 / year</span></a></li>
                                                                <li><a class="dropdown-item" href="#"><b>1,000</b> GB - <span v-if="payInYear === false">USD 290 / month</span><span  v-if="payInYear === true">USD 3,202 / year</span></a></li>
                                                            </ul>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 mb-0">
                                                <div class="curBillBox py-3 px-4 fst-italic">
                                                    <div class="mb-2">Usage</div>
                                                    <div>* 5 credits spent for each signee invitation.</div>
                                                    <div class="text-danger">* [only show to Malaysian KYCed subscribers] 2 credits to be reimbursed for each KYCed Malaysian signee.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="tab-pane container fade" id="entitykyc">
                                <div class="row">
                                    <div v-if="entityKycStatus === 'PENDING'" class="col-12 pb-3">
                                        Complete entity KYC verification by upload organization document.
                                    </div>
                                    <div v-else-if="entityKycStatus === 'SUBMITTED'" class="col-12 pb-3">
                                        We have received your entity KYC verification request. <br />Kindly allow some time for us to process accordingly.
                                    </div>
                                    <div v-else-if="entityKycStatus === 'PROCESS'" class="col-12 pb-3">
                                        Your entity KYC verification request is in progress. <br />Kindly allow us some time to verify your information and documents.
                                    </div>
                                    <div v-else-if="entityKycStatus === 'REJECTED'" class="col-12 pb-3">
                                        <div>We are sorry to inform your that your entity KYC verification is failed.</div>
                                        <div v-if="entityKycRemark" class="mb-3">Reason: {{ entityKycRemark }}</div>
                                        <div>You may review and update your organization information then re-upload organization document.</div>
                                    </div>
                                    
                                    <div v-if="allowEntityKyc === false" class="col-12">
                                        <div class="pb-2">Following data is compulsory for entity KYC verification, kindly update in 'Organization Information'</div>
	                                    <div v-if="entityName === '' || entityName === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing name of organization</div>
	                                    <div v-if="entityRegID === '' || entityRegID === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing registration ID</div>
                                        <div v-if="entityCountry === '' || entityCountry === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing country</div>
                                        <div v-if="entityUrl === '' || entityUrl === undefined"><fa icon="circle-exclamation" class="text-danger pe-2" />Missing website</div>
                                    </div>

                                    <div v-if="allowEntityKyc === true && (entityKycStatus === 'PENDING' || entityKycStatus === 'REJECTED')" class="col-12">
                                        <file-pond
                                            name="file"
                                            ref="pondK"
                                            label-idle="Drop files"
                                            v-bind:allow-multiple="true"
                                            accepted-file-types="image/jpg, image/jpeg, image/png, image/gif, application/pdf"
                                            :server="entityKycConfig"
                                            :files="myEntityKyc"
                                            storeAsFile="true"
                                            @init="handleEntityKycPondInit"
                                            @error="uplEntityKycError"
                                            @processfile="onprocessEntityKyc"
                                            allowRevert="true"
                                            allowRemove="false"
                                        />
                                        <div class="text-center small text-grey">Max file size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF, PDF only</div>
                                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Text is clearly readable without blockage or blurry</div>
                                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Images in original color, not black & white or changed in any way</div>
                                    </div>

                                    <div v-if="allowEntityKyc === true && (entityKycStatus === 'PENDING' || entityKycStatus === 'REJECTED')" class="col-12"><hr /></div>
                                    <div v-if="allowEntityKyc === true && (entityKycStatus === 'PENDING' || entityKycStatus === 'REJECTED')" class="col-12">
                                        <div v-for="(k, kIdx) in arrEntityKyc" :key="k.id">
                                            <table class="table table-borderless">
                                                <tr>
                                                    <td v-if="k.mimeType === 'application/pdf'" width="95%">
                                                        <span class="isLink" @click="downloadEntityKyc(k.dlUrl)"><fa icon="file-pdf" class="me-2" />Download</span>
                                                    </td>
                                                    <td v-else width="95%">
                                                        <ImgKycEntity :id="k.id" :width="k.width" :height="k.height" label="entity kyc" />
                                                        <!-- <img :src="'data:' + k.mimeType + ';base64, ' + k.imgBase64" style="padding: 0px;" class="img-fluid" alt="kyc" /> -->
                                                    </td>
                                                    <td class="text-end">
                                                        <Popper class="popperDark" arrow hover content="Delete image">
                                                            <fa icon="trash" size="lg" class="isLink" @click.prevent="delEntityKyc(k.id)"/>
                                                        </Popper>
                                                    </td>
                                                </tr>
                                            </table>
                                            <hr v-if="kIdx < arrEntityKyc.length-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
	
                    </div>
                    <!-- Left: Entity end -->
                </div>
            </div>

            <!-- Right column -->
            <div class="col-12 col-lg-4 d-grid">
                <div class="border shadow-sm generalContentBox">

                    <!-- Right: Manage Entity -->
                    <div v-if="pageView === 'personal'" class="row">
                        <div v-if="objEntity" class="col-12 mb-2">
                            <div v-if="uiStructure !== 'PERSONAL' && objEntity && objEntity.jLogo.entityId" @click="pageView = 'entity'; curTab = 'entity'" class="isLink">
                                <ImgLogo v-if="objEntity.jLogo.entityId" :id="objEntity.jLogo.entityId" status="" :isVerified="objEntity.isVerified" :width="objEntity.jLogo.width" :height="objEntity.jLogo.height" />
                            </div>

                            <div v-if="uiStructure === 'PERSONAL'" style="font-size: 16px" class="mt-1 fw-bold text-primary">{{ objEntity.entityName }}</div>
                            <div v-else @click="pageView = 'entity'; curTab = 'entity'" style="font-size: 16px" class="mt-1 fw-bold text-primary isLink">{{ objEntity.entityName }}</div>

                            <div class="lblTiny">
                                <div v-if="user && user.jEntity && user.jEntity.isOwner === true && user.jEntity.isFinance === true">
                                    OWNER <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> FINANCE
                                </div>
                                <div v-else-if="user && user.jEntity && user.jEntity.isOwner === true">OWNER</div>
                                <div v-else-if="user && user.jEntity && user.jEntity.isFinance === true">FINANCE</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="isLinkPrimary mb-2" @click="showVerifiedEntityRemark = !showVerifiedEntityRemark">
                                <fa icon="circle-question" size="lg" class="me-2" />Why verified entity is important?
                            </div>
                            <div v-if="showVerifiedEntityRemark" class="pb-2">
                                <div class="mb-2">Verified Organizations is a new way for organizations and their affiliates to distinguish themselves on SignOn. Rather than relying on SignOn to be the sole arbiter of truth for which accounts should be verified, vetted organizations that sign up for Verified Organizations are in full control of vetting and verifying accounts they're affiliated with.</div>
                                <div>Accounts affiliated with the organization will receive an affiliate badge on their profile with the organization's logo, and will be featured on the organization's SignOn profile, indicating their affiliation. All organizations are vetted before they can join Verified Organizations.</div>
                            </div>
                        </div>
                    </div>

                    <!-- Right: Manage Personal -->
                    <div v-if="pageView === 'entity'" class="row">

                        <div class="col-12 mb-2">
                            <table border="0">
                                <tr>
                                    <td class="align-top pt-1">
                                        <div class="d-flex justify-content-center text-center" style="width: 120px; height: 120px">
                                            <img v-if="avatar && avatar.imgBase64" :src="avatar.imgBase64" class="rounded-circle" align="center" alt="user avatar" style="position:absolute; width: 120px; height: 120px; object-fit: cover" />
                                            <fa v-else icon="user" style="position:absolute; width: 116px; height: 116px;" />

                                            <span v-if="getUserStatus === 'PENDING'" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                                <fa icon="hourglass-half" class="text-danger" />
                                            </span>
                                            <span v-else-if="getUserStatus === 'DISABLED' || getUserStatus === 'EXPIRED' || getUserStatus === 'DELETING' || getUserStatus === 'REACTIVE'" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                                <fa icon="circle-minus" class="text-danger" />
                                            </span>
                                            <span v-else-if="getUserIsVerified === true || getUserIsVerified === 'true'" id="certificate" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                                <fa icon="circle-check" class="text-success" />
                                            </span>   
                                            <span v-if="user && user.jEntity.isOwner === true">
                                                <Popper class="popperDark" arrow hover content="OWNER">
                                                    <fa icon="square-pen" class="text-blue" style="z-index: 1000; position: absolute; font-size: 28px; margin-top: 75px; margin-left: -55px" />
                                                </Popper>
                                            </span>
                                            <span v-else-if="user && user.jEntity.isEditor === true">
                                                <Popper class="popperDark" arrow hover content="EDITOR">
                                                    <fa icon="square-pen" class="text-blue" style="z-index: 1000; position: absolute; font-size: 28px; margin-top: 75px; margin-left: -550px" />
                                                </Popper>
                                            </span>
                                        </div>
                                    </td>
                                    <td class="text-start ps-2">
                                        <div class="isLink" @click="pageView = 'personal'; curTab = 'personal'">
                                            <div class="fw-bold text-primary">{{ fullLegalName }}</div>
                                            <div v-if="displayName" class="text-grey"><fa icon="at" class="mx-1"/>{{displayName}}</div>
                                        </div>
                                        <div class="small">{{email}}</div>
                                        <div><br /></div>
                                        <div>joined <span class="fst-italic">{{func.convDateTimeFormat(dateCreate, 'date')}}</span></div>
                                        <div>last login <span class="fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(dateLastLogin), new Date()) }}</span></div>
                                        <div>
                                            <span class="badge bg-secondary">{{plan}}</span>
                                            <span v-if="getUserIsVerified === true || getUserIsVerified === 'true'" class="ms-1 badge bg-success">KYC verified</span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- Right: Manage Personal end -->

                </div>

                <!-- Right: Privacy -->
                <div v-if="isSignOn === true && pageView === 'personal'" class="generalContentBox border shadow-sm bg-warning">
                    <label class="fw-bold mb-3">My Summary</label>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <table class="table" border="0">
                                <tbody>
                                    <tr>
                                        <td width="60px">
                                            <fa icon="folder" size="lg" />
                                        </td>
                                        <td>Folders</td>
                                        <td class="text-end">0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <fa icon="file" size="lg" />
                                        </td>
                                        <td>Documents</td>
                                        <td class="text-end">0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <fa icon="signature" size="lg" />
                                        </td>
                                        <td>Signed</td>
                                        <td class="text-end">0</td>
                                    </tr>
                                    <tr v-if="user">
                                        <td>
                                            <fa icon="dollar-sign" size="lg" />
                                        </td>
                                        <td>Bonus + Package Credits</td>
                                        <td class="text-end">{{ user.bonusCredit + user.planCredit }}</td>
                                    </tr>
                                    <tr v-if="user">
                                        <td>
                                            <fa icon="database" size="lg" />
                                        </td>
                                        <td>Disk Space</td>
                                        <td class="text-end">{{user.totalStorage}}</td>
                                    </tr>

                                </tbody>    
                            </table>
                        </div>
                    </div>
                </div>

                <div v-if="isSignOn === true && pageView === 'entity'" class="generalContentBox border shadow-sm bg-warning">
                    <label class="fw-bold mb-3">My Organization Summary</label>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <table class="table" border="0">
                                <tbody>
                                    <tr>
                                        <td width="60px">
                                            <fa icon="user" size="lg" />
                                        </td>
                                        <td>Members</td>
                                        <td class="text-end">0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <fa icon="folder" size="lg" />
                                        </td>
                                        <td>Folders</td>
                                        <td class="text-end">0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <fa icon="file" size="lg" />
                                        </td>
                                        <td>Documents</td>
                                        <td class="text-end">0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <fa icon="boxes-stacked" size="lg" />
                                        </td>
                                        <td>Additional Racks</td>
                                        <td class="text-end">0</td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>
                       
                    </div>
                </div>
                <!-- Right: Privacy End -->

                <div v-if="isSignOn === true && pageView === 'personal' && (getUserIsVerified === true || getUserIsVerified === 'true')" class="generalContentBox border shadow-sm bg-warning">
                    <label class="fw-bold mb-3">Request To Update Verified Data</label>
                    <div class="row">
                        <div class="col-12 mb-2">
                            Please resubmit your identity documents if you wish to change your verified information.
                        </div>
                        <div class="col-12 mb-2">
                            <a class="text-primary isLink" href="#" data-bs-toggle="modal" data-bs-target="#mdlRenewPassport">Renew passport</a>
                        </div>
                        <div class="col-12 mb-2">
                            <a class="text-primary isLink" href="#" data-bs-toggle="modal" data-bs-target="#mdlEditKYC">Edit personal information</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div v-if="curTab === 'billing' && user.jEntity.isFinance === true" class="col-12">
                <div class="generalContentBox border">
                    <div class="row">
                        <!-- Billing Left -->
                        <div class="col-lg-12 px-4 fw-bold pb-3">Billing</div>
                        <div class="col-lg-6 text-center px-4 mb-3">
                            <div class="curBillBox p-4">
                                <div>My amount due</div>
                                <div>
                                    <span class="fw-bold text-uppercase">MYR</span>
                                    <span class="fs-1 ps-1">360</span>
                                </div>
                                <div class="mb-3">by 1 Feb 2024</div>
                                <div class="row">
                                    <div class="col-6 d-grid">
                                        <button class="btn btn-outline-secondary btn-block">View Bill</button>
                                    </div>
                                    <div class="col-6 d-grid">
                                        <button @click="$router.push({ path: '/auth/preference/payment', query: { opt: 'payBill', amt: '360.00', fr: '/auth/preference'}})" class="btn btn-secondary btn-block">Pay</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End of Billing Left -->

                        <!-- Billing Right -->
                        <div class="col-lg-6 p-4">
                            <table class="table table-hover">
                                <tbody>
                                    <tr>
                                        <td>1 Feb 2023 - Bill</td>
                                        <td class="text-end">MYR 360</td>
                                        <td width="10px"></td>
                                        <td width="40px" class="text-end">
                                            <Popper class="popperDark" arrow hover content="View bill">
                                                <fa icon="chevron-right" size="lg" class="isLink"/>
                                            </Popper>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8 Feb 2023 - Payment</td>
                                        <td class="text-end">MYR 360</td>
                                        <td width="10px"></td>
                                        <td width="40px" class="text-end"></td>
                                    </tr>
                                    <tr>
                                        <td>1 Feb 2022 - Bill</td>
                                        <td class="text-end">MYR 360</td>
                                        <td width="10px"></td>
                                        <td width="40px" class="text-end">
                                            <Popper class="popperDark" arrow hover content="View bill">
                                                <fa icon="chevron-right" size="lg" class="isLink"/>
                                            </Popper>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- End of Billing Right -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal - Renew passport --> 
    <div
        class="modal fade"
        id="mdlRenewPassport"
        tabindex="-1"
        aria-labelledby="RenewPassport"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Renew Passport</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div v-if="hasKycPassportUpd === true" class="col-12 pb-3">
                            Following request is {{ objKycPassportUpd.status }} at {{ func.convDateTimeFormat(objKycPassportUpd.dateModify, 'datetime') }}.
                            <div v-if="objKycPassportUpd.status === 'REJECTED'">Reason: {{ objKycPassportUpd.remark }}</div>
                        </div>
                        
                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="passport2" id="passport2" placeholder="Passport">
                                <label for="passport2">Passport</label>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <div class="text-grey" style="font-size: 12px; font-weight: 400; line-height: 1.5; padding-bottom: 2px;">Passport expiry</div>
                                <!-- <Datepicker :modelValue="passportExpiry2" @update:modelValue="setPassportExpiry2" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker> -->
                                <VueDatePicker v-model="passportExpiry2" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <select v-model="passportIssueCountryId2" id="passportIssueCountryId2" class="form-select">
                                    <option value="">Select Country</option>
                                    <option :value="c.id" v-for="c in countryOpt" :key="c.id">{{c.name}}</option>
                                </select>
                                <label for="passportIssueCountryId2">Passport country</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div v-if="hasKycPassportUpd === true && objKycPassportUpd.status === 'SUBMITTED'">
                        <button type="button" class="btn btn-secondary me-2" data-bs-toggle="modal" data-bs-target="#mdlConfirmDelPassport" data-bs-dismiss="modal">
                            <fa icon="trash" class="me-2" />Delete
                        </button>
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                            <fa icon="xmark" class="me-2" />Close
                        </button>
                    </div>
                    <button v-else type="button" class="btn btn-secondary" @click="updNewPassport" data-bs-dismiss="modal">
                        <fa icon="envelope" class="me-2" />Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Confirm delete passport renew request --> 
    <div
        class="modal fade"
        id="mdlConfirmDelPassport"
        tabindex="-1"
        aria-labelledby="ConfirmDelPassport"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to delete passport renew request?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary me-2" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button type="button" class="btn btn-secondary" @click="delNewPassport" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Request edit KYC info --> 
    <div
        class="modal fade"
        id="mdlEditKYC"
        tabindex="-1"
        aria-labelledby="EditKYC"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Edit Personal Info</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div v-if="hasKycProfileUpd === true" class="col-12 pb-3">
                            Following request is {{ objKycProfileUpd.status }} at {{ func.convDateTimeFormat(objKycProfileUpd.dateModify, 'datetime') }}.
                            <div v-if="objKycProfileUpd.status === 'REJECTED'">Reason: {{ objKycProfileUpd.remark }}</div>
                        </div>
                        

                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="fullLegalName2" id="fullLegalName2" placeholder="Full legal name">
                                <label for="fullLegalName2">Full legal name</label>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <select v-model="gender2" id="gender2" class="form-select">
                                    <option value="">Select gender</option>
                                    <option :value="g.value" v-for="g in genderOpt" :key="g.value">{{g.label}}</option>
                                </select>
                                <label for="gender2">Gender</label>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="nric2" id="nric2" placeholder="NRIC">
                                <label for="nric2">NRIC (National Registration Identity)</label>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="address2" id="address2" placeholder="Street address">
                                <label for="address2">Street address</label>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-4">
                            <div class="form-floating">
                                <select v-model="countryId2" id="countryId2" class="form-select">
                                    <option value="">Select Country</option>
                                    <option :value="c.id" v-for="c in countryOpt" :key="c.id">{{c.name}}</option>
                                </select>
                                <label for="countryId2">Country</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div v-if="hasKycProfileUpd === true && objKycProfileUpd.status === 'SUBMITTED'">
                        <button type="button" class="btn btn-secondary me-2" data-bs-toggle="modal" data-bs-target="#mdlConfirmDelKyc" data-bs-dismiss="modal">
                            <fa icon="trash" class="me-2" />Delete
                        </button>
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                            <fa icon="xmark" class="me-2" />Close
                        </button>
                    </div>
                    <button v-else type="button" class="btn btn-secondary" @click="updKycInfo" data-bs-dismiss="modal">
                        <fa icon="envelope" class="me-2" />Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Confirm delete KYC edit profile request --> 
    <div
        class="modal fade"
        id="mdlConfirmDelKyc"
        tabindex="-1"
        aria-labelledby="ConfirmDelKyc"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to delete profile edit request?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary me-2" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button type="button" class="btn btn-secondary" @click="delKycInfo" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Combo Preview --> 
    <div
        class="modal fade"
        id="mdlComboPreview"
        tabindex="-1"
        aria-labelledby="ComboPreview"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Signature preview</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-lg-5 mb-2">
                            <div class="border" :style="'position: relative; width: '+comboW+'px; height:'+comboH+'px'">
                                <div :style="'height: ' + (comboH*comboRatio) + 'px; width: 100%; top: 0; position: absolute; z-index: 8'" class="bg-light">
                                    <div v-if="showComboSignId !== ''" :style="'position: absolute; left: 0; bottom: 0; width: ' +comboSignW+ 'px; height: ' +comboSignH+ 'px'">
                                        <ImgSign :id="showComboSignId" :width="comboSignW" :height="comboSignH" label="signature" :key="randKeySign" />
                                    </div>
                                    <div v-if="comboStructure === 'signstaoverlap' && showComboStaId !== ''" :style="'position: absolute; bottom: 0; right: 0; width: ' +comboStaW+ 'px; height: '+comboStaH+ 'px'">
                                        <ImgSign :id="showComboStaId" :width="comboStaW" :height="comboStaH" label="stamp" :key="randKeySta" />
                                    </div>
                                </div> 

                                <div :style="'position: absolute; margin-top: 7px; top: '+(comboH*comboRatio)+'px'">
                                    <div v-for="c in combo" :key="c">
                                        <div :style="'font-family: ' + fontFamily + '; font-size: ' + fontSize + 'pt; font-weight: ' + (isBold === true ? 'bold' : 'normal') + '; font-style: ' + (isItalic === true ? 'italic' : 'normal')">
                                            <p v-if="c === 'cFullLegalName' && fullLegalName !== ''" class="my-0" :style="'margin: 5px; padding-bottom: 8px; line-height: 1.2'">{{ fullLegalName }}</p>
                                            <p v-if="c === 'cDesignation' && entityDesignation !== ''" class="my-0" :style="'margin: 5px; padding-bottom: 8px; line-height: 1.2'">{{ entityDesignation }}</p>
                                            <p v-if="c === 'cOrganization' && entityName !== ''" class="my-0" :style="'margin: 5px; padding-bottom: 8px; line-height: 1.2'">{{ entityName }}</p>
                                            <p v-if="c === 'cNric' && nric !== ''" class="my-0" :style="'margin: 5px; padding-bottom: 8px; line-height: 1.2'">{{ nric }}</p>
                                            <p v-if="c === 'cPassport' && passport !== ''" class="my-0" :style="'margin: 5px; padding-bottom: 8px; line-height: 1.2'">{{ passport }}</p>
                                            <p v-if="c === 'cDateSigned'" class="my-0" :style="'margin: 5px; padding-bottom: 8px; line-height: 1.2'">{{ func.convDateTimeFormat(new Date(), 'datetext')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- select signature -->
                            <div v-if="arrSig && arrSig.length > 1" class="mt-3">
                                <div class="row">
                                    <div class="col-12 text-primary isLink" @click="showComboSignList = !showComboSignList">Select other signature</div>
                                </div>
                                
                                <div v-if="showComboSignList === true" class="row mt-2 mb-3" style="margin-left: 1px; margin-right: 1px">
                                    <div v-for="(s, sIdx) in arrSig" :key="s" class="col-4 p-1">
                                        <div class="w-100 h-100">
                                            <div class="w-100 h-100 isLink divHover" :class="showComboSignId === s.signatureId ? 'divSelected':''" @click="selectSignature(s)" style="display: grid; place-items: center; height: 80px;">
                                                <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="signature" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mb-2">
                                <div v-if="arrSig && arrSig.length !== 1" class="row">
                                    <div class="col-12">
                                        <fa icon="circle-exclamation" class="text-danger pe-2" />Missing signature. 
                                        <span class="isLink text-primary" data-bs-toggle="modal" data-bs-target="#mdlAddSig">Upload here</span>
                                    </div>
                                </div>
                            </div>

                            <!-- New stamp -->
                            <div v-if="arrSta && arrSta.length > 1" class="mt-2 mb-3">
                                <div class="row">
                                    <div class="col-12 text-primary isLink" @click="showComboStaList = !showComboStaList">Select other stamp</div>
                                </div>
                                <div v-if="showComboStaList === true" class="row mt-2" style="margin-left: 1px; margin-right: 1px">
                                    <div v-for="(s, sIdx) in arrSta" :key="s" class="col-4 p-1">
                                        <div class="w-100 h-100">
                                            <div class="w-100 h-100 isLink divHover" :class="showComboStaId === s.signatureId ? 'divSelected':''" @click="selectStamp(s)" style="display: grid; place-items: center; height: 80px">
                                                <ImgSign :id="s.signatureId" :width="s.width" :height="s.height" label="stamp"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div v-else class="mt-2 mb-2">
                                <div v-if="comboStructure === 'signstaoverlap' && showComboStaId === '' && arrSta && arrSta.length !== 1" class="row">
                                    <div class="col-12">
                                        <fa icon="circle-exclamation" class="text-danger pe-2" />Missing stamp.
                                        <span class="isLink text-primary" data-bs-toggle="modal" data-bs-target="#mdlAddSta">Upload here</span> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-7">
                            <div class="row pb-3">
                                <!-- <div class="col-12 text-danger">showComboStaId: {{showComboStaId}}</div>
                                <div class="col-12 text-danger">{{arrSta}}</div> -->

                                <div class="col-12 fw-bold mb-3">Play around to preview how the signature will look like</div>
                                <div class="col-12 mb-2">Position of signature / stamp</div>
                                <div class="col-6">
                                    <table width="100%" border="0">
                                        <tr>
                                            <td rowspan="2" width="30px" class="align-top">
                                                <input type="radio" v-model="comboStructure" value="sign" class="form-check-input" :class="signeeSelectedClass" />
                                            </td>
                                            <td class="small" :class="signeeSelectedClass">Signature only</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="80%" border="1" class="mb-2">
                                                    <tr>
                                                        <td class="border p-1 text-center"><fa icon="signature" /></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-6">
                                    <table width="100%" border="0">
                                        <tr>
                                            <td rowspan="2" width="30px"  class="align-top">
                                                <input type="radio" v-model="comboStructure" value="signstaoverlap" class="form-check-input" :class="signeeSelectedClass" />
                                            </td>
                                            <td class="small" :class="signeeSelectedClass">Signature + Stamp</td>
                                        </tr>
                                        
                                        <tr style="height: 40px">
                                            <td>
                                                <table width="80%" border="0" class="mb-2">
                                                    <tr>
                                                        <td class="text-center mb-3">
                                                            <div style="position: relative; width: 100%; margin-bottom: 30px">
                                                                <div style="position: absolute; width: 70%; top: 0; left: 30%; background-color: #e5e5e5; opacity: 0.5" class="border p-1" >
                                                                    <fa icon="stamp" />
                                                                </div>
                                                                <div style="position: absolute; width: 70%; top: 5px; left: 0px; z-index: 3; " class="border p-1">
                                                                    <fa icon="signature" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row pb-3">
                                <div class="col-12 mb-2">Data to display</div>
                                <div class="col-8">
                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >1</span>
                                        <select v-model="combo[0]" @change="changeCombo()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="cFullLegalName">Full legal name</option>
                                            <option value="cDesignation">Designation</option>
                                            <option value="cOrganization">Organization</option>
                                            <option value="cNric">NRIC</option>
                                            <option value="cPassport">Passport</option>
                                            <option value="cDateSigned">Date Signed</option>
                                        </select>
                                    </div>
                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >2</span>
                                        <select v-model="combo[1]" @change="changeCombo()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="cFullLegalName">Full legal name</option>
                                            <option value="cDesignation">Designation</option>
                                            <option value="cOrganization">Organization</option>
                                            <option value="cNric">NRIC</option>
                                            <option value="cPassport">Passport</option>
                                            <option value="cDateSigned">Date Signed</option>
                                        </select>
                                    </div>

                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >3</span>
                                        <select v-model="combo[2]" @change="changeCombo()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="cFullLegalName">Full legal name</option>
                                            <option value="cDesignation">Designation</option>
                                            <option value="cOrganization">Organization</option>
                                            <option value="cNric">NRIC</option>
                                            <option value="cPassport">Passport</option>
                                            <option value="cDateSigned">Date Signed</option>
                                        </select>
                                    </div>
                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >4</span>
                                        <select v-model="combo[3]" @change="changeCombo()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="cFullLegalName">Full legal name</option>
                                            <option value="cDesignation">Designation</option>
                                            <option value="cOrganization">Organization</option>
                                            <option value="cNric">NRIC</option>
                                            <option value="cPassport">Passport</option>
                                            <option value="cDateSigned">Date Signed</option>
                                        </select>
                                    </div>
                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >5</span>
                                        <select v-model="combo[4]" @change="changeCombo()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="cFullLegalName">Full legal name</option>
                                            <option value="cDesignation">Designation</option>
                                            <option value="cOrganization">Organization</option>
                                            <option value="cNric">NRIC</option>
                                            <option value="cPassport">Passport</option>
                                            <option value="cDateSigned">Date Signed</option>
                                        </select>
                                    </div>
                                    <div class="input-group input-group-xs">
                                        <span class="input-group-text bg-secondary text-white" >6</span>
                                        <select v-model="combo[5]" @change="changeCombo()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="cFullLegalName">Full legal name</option>
                                            <option value="cDesignation">Designation</option>
                                            <option value="cOrganization">Organization</option>
                                            <option value="cNric">NRIC</option>
                                            <option value="cPassport">Passport</option>
                                            <option value="cDateSigned">Date Signed</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-2">Signature partition</div>
                                <div class="col-12 mb-3">
                                    <input type="range" class="form-range" min="0.2" max="0.8" step="0.2" v-model="comboRatio">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <select ref="lstFontFamily" v-model="fontFamily" class="form-select form-select-xs">
                                        <option value="Courier">Courier</option>
                                        <option value="Helvetica">Helvetica</option>
                                        <option value="TimesRoman">TimesRoman</option>
                                    </select>
                                </div>

                                <div class="col-6">
                                    <table width="100%" border="0">
                                        <tr>
                                            <td width="60px">
                                                <button type="button" @click ="isBold = !isBold" class="btn btn-xs me-1" :class="isBold === true ? 'bg-secondary text-white' : 'border'" style="width: 25px">B</button>
                                                <button type="button" @click="isItalic = !isItalic" class="btn btn-xs" :class="isItalic === true ? 'bg-secondary text-white' : 'border'" style="width: 25px">I</button>
                                            </td>
                                            <td>
                                                <div class="input-group input-group-xs">
                                                    <span class="input-group-text bg-secondary text-white" :class="signeeSelectedClass">Size</span>
                                                    <select v-model="fontSize" class="form-select form-select-xs">
                                                        <option :value="n" v-for="n in fontSizeRange" :key="n">{{n}}</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                           
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - Add signature --> 
    <div
        class="modal fade"
        id="mdlAddSig"
        tabindex="-1"
        aria-labelledby="SigUpload"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add signature</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="d-flex">
                        <ul class="nav flex-column nav-pills">
                            <li class="nav-item text-center">
                                <a class="nav-link active" href="#sigUpload" data-bs-toggle="tab">
                                    <fa icon="upload" />
                                    <div class="small text-nowrap">Upload</div>
                                </a>
                            </li>
                            <li class="nav-item text-center">
                                <a class="nav-link" href="#sigType" data-bs-toggle="tab">
                                    <fa icon="keyboard"/>
                                    <div class="small text-nowrap">Type</div>
                                </a>
                            </li>
                            <li class="nav-item text-center">
                                <a class="nav-link" href="#sigDraw" data-bs-toggle="tab">
                                    <fa icon="pen"/>
                                    <div class="small text-nowrap">Draw</div>
                                </a>
                            </li>
                            <li v-if="isSignOn === true" class="nav-item text-center bg-warning">
                                <a class="nav-link" href="#sigNonImage" data-bs-toggle="tab" @click="randKeyNonImg = Math.floor(Math.random() * 1000)">
                                    <fa icon="address-card"/>
                                    <div class="small text-nowrap">Data Signature</div>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content ps-3 w-100">
                            <div id="sigUpload" class="tab-pane active">
                                <div v-if="isUploadingSig === true" class="mb-2">
                                    <fa icon="spinner" size="lg" class="me-2" />Loading...
                                </div>

                                <file-pond
                                    name="file"
                                    class="signature" 
                                    ref="pondS"
                                    label-idle="Drop image"
                                    v-bind:allow-multiple="false"
                                    accepted-file-types="image/jpeg, image/png, image/gif"
                                    :files="mySignatures"
                                    storeAsFile="true"
                                    @init="handleSignaturePondInit"
                                    @error="uplSignatureError"
                                    @processfile="onprocessSignature"
                                    @addfilestart="addSignatureStart"
                                    allowRevert="true"
                                    allowRemove="false" 
                                />

                                <canvas id="canvasS" ref="canvasS" style="display: none">{{canvasSContext}}</canvas>
                                <div><img :src="canvasSContextSrc" style="display: none" alt="signature" /></div>

                                <div class="text-center small text-grey pb-5">
                                    Max image size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF only
                                </div>
                            </div>

                            <div id="sigType" class="tab-pane">
                                <div class="pb-2">
                                    <canvas id="sigCanvas" ref='sigCanvas' width="300" height="80" tabindex='0' style="border:1px solid var(--accent-color);" >{{sigText}}</canvas>
                                </div>
                                <div class="pb-2">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" v-model="sigText" id="sigName" placeholder="Name">
                                        <label for="signName">Type your signature</label>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <table width="100%" border="0">
                                        <tr>
                                            <td>
                                                <select v-model="sigFontFamily" class="form-select">
                                                    <option value="Arial">Arial</option>
                                                    <option value="ClickerScript">Clicker Script</option>
                                                    <option value="DancingScript">Dancing Script</option>
                                                    <option value="ImperialScript">Imperial Script</option>
                                                    <option value="LuxuriousScript">Luxurious Script</option>
                                                    <option value="MarckScript">Marck Script</option>
                                                    <option value="MaShanZheng">Ma Shan Zheng</option>
                                                    <option value="MeieScript">Meie Script</option>
                                                    <option value="PetitFormalScript">Petit Formal Script</option>
                                                    <option value="PinyonScript">Pinyon Script</option>
                                                    <option value="Poppins">Poppins</option>
                                                    <option value="RougeScript">Rouge Script</option>
                                                    <option value="StyleScript">Style Script</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="sigFontSize" class="form-select" >
                                                    <option value="38px">38</option>
                                                    <option value="40px">40</option>
                                                    <option value="42px">42</option>
                                                    <option value="44px">44</option>
                                                    <option value="46px">46</option>
                                                    <option value="48px">48</option>
                                                </select>
                                            </td>
                                            <td><input type="color" class="form-control form-control-color" v-model="sigFontColor" title="Choose a color"></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="text-end pt-2">
                                    <button v-if="sigText !== ''" type="button" class="btn btn-outline-secondary me-2" @click="sigText = ''">Reset</button>
                                    <button v-if="sigText !== ''" type="button" class="btn btn-secondary" @click="uplSigText" data-bs-dismiss="modal">Save</button>
                                </div>
                            </div>

                            <div id="sigDraw" class="tab-pane">
                                <div class="pb-2">
                                    <vue-drawing-canvas ref="vueCanvasDS" v-model:image="imgDS" 
                                        canvasId="vueCanvasDS" 
                                        :width="350"
                                        :height="200"
                                        stroke-type="dash"
                                        line-cap="square"
                                        line-join="round"
                                        :fill-shape="false"
                                        :eraser="false"
                                        :lineWidth="2"
                                        :color="imgDSColor"
                                        background-color="#FFFFFF"
                                        :background-image="null"
                                        :watermark="null"
                                        saveAs="png"
                                        :styles="{
                                            border: 'solid 1px var(--accent-color)',
                                        }"
                                        :lock="false"
                                        :additional-images="[]" 
                                    />
                                </div>
                                <div>
                                    <img :src="imgDS" style="display: none" alt="signature" />
                                    <canvas id="canvasDS" ref="canvasDS" style="display: none">{{canvasDSContext}}</canvas>
                                </div>
                                <div class="pb-2">
                                    <table width="100%" border="0">
                                        <tr>
                                            <td width="50px">
                                                <input type="color" class="form-control form-control-color" v-model="imgDSColor" title="Choose a color">
                                            </td>
                                            <td class="text-end pt-2">
                                                <button type="button" class="btn btn-outline-secondary me-2" @click.prevent="$refs.vueCanvasDS.reset()">Clear</button>
                                                <button type="button" class="btn btn-secondary" @click="uplDrawSign">Save</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div id="sigNonImage" class="tab-pane">
                                <div class="mb-4">
                                    <SignatureTemplate :data="arrNonImgPreview" templateId="1" :key="randKeyNonImg"/>
                                </div>
                                
                                <div class="mb-2">Data to be displayed as a signature</div>
                                
                                <div>
                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >1</span>
                                        <select v-model="arrNonImgSign[0]" @change="changeNonImgSign()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="fullLegalName">Full legal name</option>
                                            <option value="designation">Designation</option>
                                            <option value="organization">Organization</option>
                                            <option value="nric">NRIC</option>
                                            <option value="passport">Passport</option>
                                            <!-- <option value="dateSigned">Date Signed</option> -->
                                        </select>
                                    </div>
                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >2</span>
                                        <select v-model="arrNonImgSign[1]" @change="changeNonImgSign()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="fullLegalName">Full legal name</option>
                                            <option value="designation">Designation</option>
                                            <option value="organization">Organization</option>
                                            <option value="nric">NRIC</option>
                                            <option value="passport">Passport</option>
                                            <!-- <option value="dateSigned">Date Signed</option> -->
                                        </select>
                                    </div>

                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >3</span>
                                        <select v-model="arrNonImgSign[2]" @change="changeNonImgSign()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="fullLegalName">Full legal name</option>
                                            <option value="designation">Designation</option>
                                            <option value="organization">Organization</option>
                                            <option value="nric">NRIC</option>
                                            <option value="passport">Passport</option>
                                            <!-- <option value="dateSigned">Date Signed</option> -->
                                        </select>
                                    </div>
                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >4</span>
                                        <select v-model="arrNonImgSign[3]" @change="changeNonImgSign()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="fullLegalName">Full legal name</option>
                                            <option value="designation">Designation</option>
                                            <option value="organization">Organization</option>
                                            <option value="nric">NRIC</option>
                                            <option value="passport">Passport</option>
                                            <!-- <option value="dateSigned">Date Signed</option> -->
                                        </select>
                                    </div>
                                    <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >5</span>
                                        <select v-model="arrNonImgSign[4]" @change="changeNonImgSign()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="fullLegalName">Full legal name</option>
                                            <option value="designation">Designation</option>
                                            <option value="organization">Organization</option>
                                            <option value="nric">NRIC</option>
                                            <option value="passport">Passport</option>
                                            <!-- <option value="dateSigned">Date Signed</option> -->
                                        </select>
                                    </div>
                                    <!-- <div class="input-group input-group-xs mb-2">
                                        <span class="input-group-text bg-secondary text-white" >6</span>
                                        <select v-model="arrNonImgSign[5]" @change="changeNonImgSign()" class="form-select form-select-xs">
                                            <option value="">-- remove --</option>
                                            <option value="fullLegalName">Full legal name</option>
                                            <option value="designation">Designation</option>
                                            <option value="organization">Organization</option>
                                            <option value="nric">NRIC</option>
                                            <option value="passport">Passport</option>
                                            <option value="dateSigned">Date Signed</option>
                                        </select>
                                    </div> -->

                                    <div class="text-end pt-2">
                                    <button v-if="sigText !== ''" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Save</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- body end -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Add initial --> 
    <div
        class="modal fade"
        id="mdlAddIni"
        tabindex="-1"
        aria-labelledby="IniUpload"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add initial</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="d-flex">
                        <ul class="nav flex-column nav-pills">
                            <li class="nav-item text-center">
                                <a class="nav-link active" href="#iniUpload" data-bs-toggle="tab">
                                    <fa icon="upload"/>
                                    <div class="small">Upload</div>
                                </a>
                            </li>
                            <li class="nav-item text-center">
                                <a class="nav-link" href="#iniType" data-bs-toggle="tab">
                                    <fa icon="keyboard"/>
                                    <div class="small">Type</div>
                                </a>
                            </li>
                            <li class="nav-item text-center">
                                <a class="nav-link" href="#iniDraw" data-bs-toggle="tab">
                                    <fa icon="pen"/>
                                    <div class="small">Draw</div>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content ps-3 w-100">
                            <div id="iniUpload" class="tab-pane active">
                                <div v-if="isUploadingIni === true" class="mb-2">
                                    <fa icon="spinner" size="lg" class="me-2" />Loading...
                                </div>

                                <file-pond
                                    name="file"
                                    class="initial"
                                    ref="pondI"
                                    label-idle="Drop image"
                                    v-bind:allow-multiple="false"
                                    accepted-file-types="image/jpeg, image/png, image/gif,"
                                    :files="myInitials"
                                    storeAsFile="true"
                                    @init="handleInitialPondInit"
                                    @error="uplInitialError"
                                    @processfile="onprocessInitial"
                                    @addfilestart="addInitialStart"
                                    allowRevert="true"
                                    allowRemove="false"
                                />

                                <canvas id="canvasI" ref="canvasI" style="display: none">{{canvasIContext}}</canvas>
                                <div><img :src="canvasIContextSrc" style="display: none" alt="initial" /></div>

                                <div class="text-center small text-grey pb-5">
                                    Max image size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF only
                                </div>
                            </div>

                            <div id="iniType" class="tab-pane">
                                <div class="pb-2">
                                    <canvas id="iniCanvas" ref='iniCanvas' width="300" height="80" tabindex='0' style="border:1px solid var(--accent-color);" >{{iniText}}</canvas>
                                </div>
                                <div class="pb-2">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" v-model="iniText" id="iniName" placeholder="Name">
                                        <label for="signName">Type your initial</label>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <table width="100%" border="0">
                                        <tr>
                                            <td>
                                                <select v-model="iniFontFamily" class="form-select">
                                                    <option value="Arial">Arial</option>
                                                    <option value="ClickerScript">Clicker Script</option>
                                                    <option value="DancingScript">Dancing Script</option>
                                                    <option value="ImperialScript">Imperial Script</option>
                                                    <option value="LuxuriousScript">Luxurious Script</option>
                                                    <option value="MarckScript">Marck Script</option>
                                                    <option value="MaShanZheng">Ma Shan Zheng</option>
                                                    <option value="MeieScript">Meie Script</option>
                                                    <option value="PetitFormalScript">Petit Formal Script</option>
                                                    <option value="PinyonScript">Pinyon Script</option>
                                                    <option value="Poppins">Poppins</option>
                                                    <option value="RougeScript">Rouge Script</option>
                                                    <option value="StyleScript">Style Script</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="iniFontSize" class="form-select" >
                                                    <option value="38px">38</option>
                                                    <option value="40px">40</option>
                                                    <option value="42px">42</option>
                                                    <option value="44px">44</option>
                                                    <option value="46px">46</option>
                                                    <option value="48px">48</option>
                                                </select>
                                            </td>
                                            <td><input type="color" class="form-control form-control-color" v-model="iniFontColor" title="Choose a color"></td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <button v-if="iniText !== ''" type="button" class="btn btn-outline-secondary me-2" @click="iniText = ''">Reset</button>
                                    <button v-if="iniText !== ''" type="button" class="btn btn-secondary" @click="uplIniText" data-bs-dismiss="modal">Save</button>
                                </div>
                            </div>

                            <div id="iniDraw" class="tab-pane">
                                <div class="pb-2">
                                    <vue-drawing-canvas ref="vueCanvasDI" v-model:image="imgDI" 
                                        canvasId="vueCanvasDI" 
                                        :width="350"
                                        :height="200"
                                        stroke-type="dash"
                                        line-cap="square"
                                        line-join="round"
                                        :fill-shape="false"
                                        :eraser="false"
                                        :lineWidth="2"
                                        :color="imgDIColor"
                                        background-color="#FFFFFF"
                                        :background-image="null"
                                        :watermark="null"
                                        saveAs="png"
                                        :styles="{
                                            border: 'solid 1px var(--accent-color)',
                                        }"
                                        :lock="false"
                                        :additional-images="[]" 
                                    />
                                </div>
                                <div>
                                    <img :src="imgDI" style="display: none" alt="initial" />
                                    <canvas id="canvasDI" ref="canvasDI" style="display: none">{{canvasDIContext}}</canvas>
                                </div>
                                <div class="pb-2">
                                    <table width="100%" border="0">
                                        <tr>
                                            <td width="50px">
                                                <input type="color" class="form-control form-control-color" v-model="imgDIColor" title="Choose a color">
                                            </td>
                                            <td class="text-end">
                                                <button type="button" class="btn btn-outline-secondary me-2" @click.prevent="$refs.vueCanvasDI.reset()">Clear</button>
                                                <button type="button" class="btn btn-secondary" @click="uplDrawInit">Save</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- body end -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Add stamp --> 
    <div
        class="modal fade"
        id="mdlAddSta"
        tabindex="-1"
        aria-labelledby="StaUpload"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add stamp</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="isUploadingSta === true" class="mb-2">
						<fa icon="spinner" size="lg" class="me-2" />Loading...
					</div>

					<file-pond
						name="file"
						class="stamp"
						ref="pondSt"
						label-idle="Drop image"
						v-bind:allow-multiple="false"
						accepted-file-types="image/jpeg, image/png, image/gif,"
						:files="myStamps"
						storeAsFile="true"
						@error="uplStampError"
						@processfile="onprocessStamp"
						@addfilestart="addStampStart"
						allowRevert="true"
						allowRemove="false"
					/>

                    <canvas id="canvasSt" ref="canvasSt" style="display: none">{{canvasStContext}}</canvas>
					<div><img :src="canvasStContextSrc" style="display: none" alt="stamp" /></div>

					<div class="text-center small text-grey pb-5">
						Max image size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF only
					</div>
                </div><!-- body end -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Upload Avatar --> 
    <div
        class="modal fade"
        id="mdlAvatar"
        tabindex="-1"
        aria-labelledby="UplAvatar"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Upload Avatar</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <!-- <div class="col-12">New uploaded avatar will replace your current avatar</div> -->
                        <div class="col-12">
                            <file-pond
                                name="file"
                                ref="pondA"
                                label-idle="Drop image"
                                v-bind:allow-multiple="false"
                                accepted-file-types="image/jpeg, image/png, image/gif,"
                                :server="avatarConfig" 
                                :files="myAvatars"
                                storeAsFile="true"
                                @init="handleAvatarPondInit"
                                @error="uplAvatarError"
                                @processfile="onprocessAvatar"
                                allowRevert="true"
                                allowRemove="false"
                            />
                            <div class="text-center small text-grey">Min. 120 x 120 pixels <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Max image size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Upload Entity --> 
    <div
        class="modal fade"
        id="mdlEntityUpload"
        tabindex="-1"
        aria-labelledby="EntityUpload"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Upload Organization Logo</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <file-pond
                                name="file"
                                ref="pondE"
                                label-idle="Drop image"
                                v-bind:allow-multiple="false"
                                accepted-file-types="image/jpeg, image/png, image/gif,"
                                :server="entityConfig" 
                                :files="myEntities"
                                storeAsFile="true"
                                @init="handleEntityPondInit"
                                @error="uplEntityError"
                                @processfile="onprocessEntity"
                                allowRevert="true"
                                allowRemove="false"
                            />
                            <div class="text-center small text-grey">Max. 200 x 200 pixels <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Max image size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Email Verification Required --> 
    <div class="modal fade" id="mdlUpdProfile" tabindex="-1" aria-labelledby="UpdProfile" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Email Verification Required</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-4">In order to use this feature, you must first verify your email address. An email has been sent to {{getUserId}}. Please look for this email and click the activation link to continue.</div>
                    <div><hr /></div>
                    <div class="mb-2">If you entered your email address incorrectly, please update your primary email address below</div>
                    <div class="mb-2"><input type="email" v-model="newEmail" :placeholder="getUserId" class="form-control" /></div>
                    <div class="d-grid">
                        <button class="btn btn-secondary btn-block" :disabled="newEmail.length === 0" @click="resendActivation" data-bs-dismiss="modal">Resend Activation Email</button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal" >Close</button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End-->

    <!-- Modal - Permission --> 
    <div
        class="modal fade"
        id="mdlFetchConsent"
        tabindex="-1"
        aria-labelledby="FetchConsent"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Permission</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">
                            By clicking 'Continue', you agree to our <span class="text-primary isLink">Terms</span> and confirm {{fetchFr}} override your <b>About me</b> and <b>Working experiences</b> in SignOn.
                        </div>
                        <div class="col-12 mb-2">
                            The data fetching processes are done in ONE time only
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#mdlFetchLogin">
                        <fa icon="arrow-right" class="me-2" />Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

    <!-- Modal - Fetch login --> 
    <div
        class="modal fade"
        id="mdlFetchLogin"
        tabindex="-1"
        aria-labelledby="FetchLogin"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Login to {{fetchFr }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <input type="text" v-model="uname" class="form-control" placeholder="Username"/>
                        </div>
                        <div class="col-12 mb-2">
                            <input type="text" v-model="pass" class="form-control" placeholder="Password"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="right-to-bracket" class="me-2" />Login
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->
    
    <!-- Modal - mdlRemarkStruture --> 
    <div
        class="modal fade"
        id="mdlRemarkStruture"
        tabindex="-1"
        aria-labelledby="RemarkStructure"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Structure</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Personal</th>
                                        <th>Team</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li>Verify signees</li>
                                                <li>Verify entities, MNC, branch, country</li>
                                                <li>Verify documents</li>
                                                <li>View full public documents</li>
                                                <li>View information of private documents</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <div>Everything from Personal</div>
                                            <div class="fw-bold mt-3">PLUS</div>
                                            <ul>
                                                <li>Team members</li>
                                                <li>Team permission</li>
                                                <li>Team report</li>
                                                <li>Workflow</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

    <!-- Modal - mdlEditStruture : confirm change structure --> 
    <div
        class="modal fade"
        id="mdlEditStruture"
        tabindex="-1"
        aria-labelledby="EditStructure"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Change structure</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">Confirm the change to the {{newUiStructure}} structure?</div>
                        <div class="col-12 text-grey">You will only be able to see the structure changes after you login again.</div>
                        
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button type="button" class="btn btn-secondary" @click="updStructure(newUiStructure)" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" size="lg" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

</template>

<script>
import { ref, inject, onMounted, computed, watch, resolveDirective } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import funcs from '@/functions/function'
import UpgradeStep from '@/components/UpgradeStep.vue'
import ImgLogo from '@/components/ImgLogo.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import ImgSign from '@/components/ImgSign.vue'
import ImgKyc from '@/components/ImgKyc.vue'
import ImgKycEntity from '@/components/ImgKycEntity.vue'
import SignatureTemplate from '@/components/SignatureTemplate.vue'
import VueDrawingCanvas from 'vue-drawing-canvas'
/* import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css' */
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'


// Image Uploader for avatar
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
    name: 'ManageAccount',
    components: {
        TopNavigation, Alert, Popper, FilePond, Tooltip, UpgradeStep, ImgAvatar, ImgLogo, ImgSign, ImgKyc, ImgKycEntity, VueDrawingCanvas, VueDatePicker /* Datepicker */, SignatureTemplate
    },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const store = useStore()
        const { getUserId, getUserStatus, getUserIsVerified, getStructure } = useStore()

        const isSignOn = ref(false) // detect if from signon Entity
        const pageView = ref('personal') // personal/entity

        const user = ref(null)
        const email = ref(null)
        const newEmail = ref('')
        const fullLegalName = ref('')
        const displayName = ref('')
        const newDisplayName = ref('')
        const nric = ref('')
        const dob = ref('')
        const title = ref('Mr')
        const gender = ref('M')
        const kyc = ref('frontMyKad')
        const gmt = ref('+8:00')
        const avatar = ref('')
        const aboutMe = ref('')
        const workingExp = ref('')
        const uiStructure = ref('PERSONAL') // PERSONAL/TEAM/WHITELABEL
        const newUiStructure = ref('PERSONAL')
        const address = ref('')
        const postcode = ref('')
        const state = ref('')
        const countryId = ref('')
        const passport = ref('')
        const passportExpiry = ref('')
        const passportIssueCountryId = ref('')
        const facebook = ref('')
        const twitter = ref('')
        const discord = ref('')
        const isEntityVerified = ref(false)

        const hasKycProfileUpd = ref(false)
        const hasKycPassportUpd = ref(false)
        const kycProfileDate = ref(null)
        const kycProfileStatus = ref(null)
        const kycPassportDate = ref(null)
        const kycPassportStatus = ref(null)
        const objKycPassportUpd = ref(null)
        const objKycProfileUpd = ref(null)

        const passport2 = ref('')
        const passportExpiry2 = ref('')
        const passportIssueCountryId2 = ref('')
        const allowKyc = ref(false) // Only allow user proceed upload kyc after full legal name, gender, dob etc have been filled in 
        const allowEntityKyc = ref(false) // Only allow user proceed upload entity kyc after entityName have been filled in 

        const fullLegalName2 = ref('')
        const nric2 = ref('')
        const gender2 = ref('M')
        const address2 = ref('')
        const countryId2 = ref('')

        const logoSrc = ref('')
        const avatarSrc = ref('')
        const dateCreate = ref('')
        const dateLastLogin = ref('')
        const plan = ref('')
        const curTab = ref('personal')
        const fetchFr = ref('google')

        const passwordFormat = ref(false)
        const passwordMatch = ref(false)
        const pwd_length = ref(false)
        const pwd_number = ref(false)
        const pwd_lowercase = ref(false)
        const pwd_uppercase = ref(false)
        const pwd_special = ref(false)
        const uname = ref(null)
        const pass = ref(null)

        const cameraGap = ref(-12)   // for logo upload

        const showDisplayName = ref(true)
        const showFullLegalName = ref(true)
        const showAboutMe = ref(false)
        const showWorkingExp = ref(false)
        const showOrganization = ref(false)
        const showSignRemark = ref(false)
        const showVerifiedEntityRemark = ref(false)

        // combo signature preview
        const showComboFullLegalName = ref(true)
        const showComboDesignation = ref(true)
        const showComboOrganization = ref(true)
        const showComboNric = ref(false)
        const showComboPassport = ref(false)
        const showComboDateSigned = ref(false)
        const showComboSignId = ref('')
        const showComboSignSrc = ref('')
        const showComboSignW = ref(0)
        const showComboSignH = ref(0)
        const showComboSignList = ref(false)
        const showComboStaList = ref(false)

        const showComboStaId = ref('')
        const showComboStaSrc = ref('')

        const comboW = ref(250)
        const comboH = ref(300)
        const comboStructure = ref('sign') // sign/signstaoverlap
        const comboRatio = ref(0.4) // height percentage of signature/stamp box in comboSignature box (not signature/stamp height)
        const combo = ref(['cFullLegalName'])
        const fontFamily = ref('Helvetica')
        const fontSize = ref(10)
        const fontSizeRange = ref([])
        const isBold = ref(false)
        const isItalic = ref(false) 

        const comboSignWOrig = ref(385)
        const comboSignHOrig = ref(224)
        const comboStaWOrig = ref(648)
        const comboStaHOrig = ref(648)

        const comboSignW = ref(385)
        const comboSignH = ref(224)
        const comboStaW = ref(648)
        const comboStaH = ref(648)

        const showKycNric = ref(true)
        const showKycPassport = ref(true)
        const randKeySign = ref(1)
        const randKeySta = ref(1)

        const signMaxW = ref(250)
        const signMaxH = ref(120)

        const gmtOpt = ref(null)
        const titleOpt = ref(null)
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]
        const kycOpt = [
            { label: 'Front', value: 'frontMyKad'},
            { label: 'Back', value: 'backMyKad'}
        ]
        const countryOpt = ref(null)

        const entityName = ref(null)
        const entityShortName = ref(null)
        const entityDesignation = ref('')
        const entityCountry = ref('')
        const entityTotalMember = ref(0)
        const entityUrl = ref('')
        const entityFacebook = ref('')
        const entityTwitter = ref('')
        const entityDiscord = ref('')
        const entityRegID = ref(null)

        const payInYear = ref(false)

        const oldPassword = ref(null)
        const password = ref(null)
        const password2 = ref(null)

        const arrSig = ref([])
        const arrIni = ref([])
        const arrSta = ref([])
        const arrKycNric = ref([])
        const arrKycPassport = ref([])
        const arrEntityKyc = ref([])
        const showMoreSig = ref(false)
        const showMoreIni = ref(false)
        const showMoreSta = ref(false)

        const sigCanvas = ref(null)
        const sigContext = ref(null)
        const sigText = ref('')
        const sigImg = ref(null)
        const sigFontFamily = ref('DancingScript')
        const sigFontSize = ref('40px')
        const sigFontColor = ref('#000000')

        const iniCanvas = ref(null)
        const iniContext = ref(null)
        const iniText = ref('')
        const iniImg = ref(null)
        const iniFontFamily = ref('DancingScript')
        const iniFontSize = ref('40px')
        const iniFontColor = ref('#000000')

        const isUploadingSig = ref(false)
        const isUploadingIni = ref(false)
        const isUploadingSta = ref(false)
        
        const selectBillTab = ref(false)
        const upgradeStep = ref(0)
        const upgradePassword = ref(null)
        const upgradeEmail = ref(store.getUserId)
        const isValidPassword = ref(false) // in upgradeStep = 1
        const isValidTac = ref(false) // in upgradeStep = 2
        const isPaymentSuccess = ref(false) // in upgradeStep = 3

        const objTeam = ref(null)
        const objEntity = ref(null)
        const isAdmin = ref(false)
        const entityKycStatus = ref(null)
        const entityKycRemark = ref(null)
        const userKycStatus = ref(null)
        const userKycRemark = ref(null) // kyc remark for signee

        const pondA = ref(null)
        const pondE = ref(null)
        const pondK = ref(null)
        const pondS = ref(null)
        const pondI = ref(null)
        const pondKyc = ref(null)   // personal - nric
        const pondKyc2 = ref(null)  // personal - passport

        const canvasS = ref(null)
        const canvasSContext = ref(null)
        const canvasSContextSrc = ref(null)

        const canvasI = ref(null)
        const canvasIContext = ref(null)
        const canvasIContextSrc = ref(null)

        const canvasSt = ref(null)
        const canvasStContext = ref(null)
        const canvasStContextSrc = ref(null)

        const vueCanvasDS = ref(null)
        const imgDS = ref(null)
        const imgDSColor = ref('#000000')
        const canvasDS = ref(null)
        const canvasDSContext = ref(null)
        
        const vueCanvasDI = ref(null)
        const imgDI = ref(null)
        const imgDIColor = ref('#000000')
        const canvasDI = ref(null)
        const canvasDIContext = ref(null)

        const arrNonImgSign = ref([])
        const arrNonImgPreview = ref([])

        const randKeyNonImg = ref(1)
        const dateSignIdSample = ref('EA1EF1F09B181EF973EA2EB3A8803120')

        const avatarConfig = ref({ 
            "url": axios.defaults.baseURL + "user/avatar/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        const entityConfig = ref({ 
            "url": axios.defaults.baseURL + "entity/logo/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        const entityKycConfig = ref({ 
            "url": axios.defaults.baseURL + "entity/kyc/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 
            
        const kycConfig = ref({
            "url": axios.defaults.baseURL + "user/kyc/upload/" + kyc.value, 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        const kyc2Config = ref({
            "url": axios.defaults.baseURL + "user/kyc/upload/passport", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        watch([arrNonImgPreview], () => {
            randKeyNonImg.value = Math.floor(Math.random() * 1000)
        })

        watch([comboRatio, comboStructure], () => {
            // console.info('WATCH: comboRatio', comboStructure.value)
            const maxW = comboW.value
            const maxH = comboH.value * comboRatio.value

            if (comboStructure.value === 'signstaoverlap') {
                if (comboStaWOrig.value > maxW || comboStaHOrig.value > maxH) {
                    resizeImg('sta', comboStaWOrig.value, comboStaHOrig.value)
                } else {
                    enlargeImg('sta', comboStaWOrig.value, comboStaHOrig.value)
                }
            }
            
            if (comboSignWOrig.value > maxW || comboSignHOrig.value > maxH) {
                resizeImg('sign', comboSignWOrig.value, comboSignHOrig.value)
            } else {
                enlargeImg('sign', comboSignWOrig.value, comboSignHOrig.value)
            }

            // randKey.value = Math.floor(Math.random() * 1000)
            
        })

        watch([pageView], () => {
            if (pageView.value === 'entity') {
                getEntityKycList()

            }

        })

        watch([sigText, sigFontFamily, sigFontSize, sigFontColor], () => {
            convertSigTextToImg()

        })

        watch([iniText, iniFontFamily, iniFontSize, iniFontColor], () => {
            convertIniTextToImg()

        })

        /* watch([arrSta], () => {
            console.info('watch arrSta', JSON.stringify(arrSta.value))
            if (arrSta.value.length === 0 || !arrSta.value.includes(showComboStaId.value)) {
                showComboStaId.value = ''
                getHistoryList('stamp')
            }
        }) */

        const showComboPreview = () => {

            showComboSignList.value = false
            showComboStaList.value = false

            if (arrSig.value.length === 0 || !arrSig.value.includes(showComboSignId.value)) {
                showComboSignId.value = ''
                getHistoryList('signature')
            }

            if (arrSta.value.length === 0 || !arrSta.value.includes(showComboStaId.value)) {
                showComboStaId.value = ''
                getHistoryList('stamp')
            }
        }

        const changeNonImgSign = () => {
            let arr = []
            let arr2 = []

            arrNonImgSign.value.forEach((val, idx) => {
                if (val !== '' && val !== null && val !== undefined && !arr.includes(val)) {
                    arr.push(val)

                    if (val === 'fullLegalName' && fullLegalName.value != '' && fullLegalName.value != null) {
                        arr2.push(fullLegalName.value)
                    } else if (val === 'designation' && entityDesignation.value != '' && entityDesignation.value != null) {
                        arr2.push(entityDesignation.value)
                    } else if (val === 'organization' && entityName.value != '' && entityName.value != null) {
                        arr2.push(entityName.value)
                    } else if (val === 'nric' && nric.value != '' && nric.value != null) {
                        arr2.push(nric.value)
                    } else if (val === 'passport' && passport.value != '' && passport.value != null) {
                        arr2.push(passport.value)
                    } else if (val === 'dateSigned') {
                        arr2.push(func.convDateTimeFormat(new Date(), 'datetext'))
                    }
                }
            })

            arrNonImgSign.value = []
            arrNonImgSign.value = arr

            arrNonImgPreview.value = []
            arrNonImgPreview.value = arr2

        }

        const changeCombo = () => {
            // console.info('changeCombo', JSON.stringify(combo.value))
            let arr = []
            combo.value.forEach((val, idx) => {
                if (val !== '' && val !== null && val !== undefined && !arr.includes(val)) {
                    arr.push(val)
                }
            })

            combo.value = []
            combo.value = arr
        }

        const selectSignature = (s) => {
            // Default combo preview
            showComboSignId.value = s.signatureId
            showComboSignSrc.value = s.imgBase64

            comboSignWOrig.value = s.width
            comboSignHOrig.value = s.height
            comboSignW.value = s.width
            comboSignH.value = s.height

            const maxW = comboW.value
            const maxH = comboH.value * comboRatio.value

            if (comboSignWOrig.value > maxW || comboSignHOrig.value > maxH) {
                resizeImg('sign', comboSignWOrig.value, comboSignHOrig.value)
            } else {
                enlargeImg('sign', comboSignWOrig.value, comboSignHOrig.value)
            }
        }

        const selectStamp = (s) => {
            // Default combo preview
            showComboStaId.value = s.signatureId
            showComboStaSrc.value = s.imgBase64

            comboStaWOrig.value = s.width
            comboStaHOrig.value = s.height
            comboStaW.value = s.width
            comboStaH.value = s.height

            const maxW = comboW.value
            const maxH = comboH.value * comboRatio.value

            if (comboStaWOrig.value > maxW || comboStaHOrig.value > maxH) {
                resizeImg('sta', comboStaWOrig.value, comboStaHOrig.value)
            } else {
                enlargeImg('sta', comboStaWOrig.value, comboStaHOrig.value)
            }
        }

        const resizeImg = async (imgType, imgW, imgH) => {
            const maxW = comboW.value
            const maxH = comboH.value * comboRatio.value
            const aspectRatio = imgW / imgH

            let newWidth = imgW
            let newHeight = imgH

            /* ISSUE: failed to get bigger image when enlarge from small */
            let i = 0
            // Resize based on max constraints, limit max resize 5 times to avoid unfinite loop
            while ((imgW > maxW || imgH > maxH) && i <= 5) {
                i = i + 1
                if (imgW / maxW > imgH / maxH) {
                    // Width is the limiting factor
                    newWidth = maxW
                    newHeight = maxW / aspectRatio
                } else {
                    // Height is the limiting factor
                    newHeight = maxH
                    newWidth = maxH * aspectRatio
                }

                imgW = newWidth
                imgH = newHeight
            }
                

            if (imgType === 'sta') {
                // stamp
                comboStaW.value = Math.floor(newWidth)
                comboStaH.value = Math.floor(newHeight)

                randKeySta.value = Math.floor(Math.random() * 1000)

            } else {
                // signature
                comboSignW.value = Math.floor(newWidth)
                comboSignH.value = Math.floor(newHeight)

                randKeySign.value = Math.floor(Math.random() * 1000)
            }
            
        }

        const enlargeImg = async (imgType, imgW, imgH) => {
            // console.info('enlargeImg')
            const maxW = comboW.value
            const maxH = comboH.value * comboRatio.value

            let newWidth = imgW
            let newHeight = imgH

            let i = 0
            while ((imgW < maxW && imgH < maxH) && i <= 10) {
                i = i + 1

                newHeight = newHeight * 1.1
                newWidth = newWidth * 1.1

                imgW = newWidth
                imgH = newHeight
            }

            if (imgType === 'sta') {
                // stamp
                comboStaW.value = Math.floor(newWidth)
                comboStaH.value = Math.floor(newHeight)

                if (comboStaW.value > maxW || comboStaH.value > maxH) {
                    resizeImg('sta', comboStaW.value, comboStaH.value)
                } else {
                    randKeySta.value = Math.floor(Math.random() * 1000)
                }

            } else {
                // signature
                comboSignW.value = Math.floor(newWidth)
                comboSignH.value = Math.floor(newHeight)

                if (comboSignW.value > maxW || comboSignH.value > maxH) {
                    resizeImg('sign', comboSignW.value, comboSignH.value)
                } else {
                    randKeySign.value = Math.floor(Math.random() * 1000)
                }
            }
            
        }

        const updPrivacy = () => {
            const p = {
                showDisplayName: showDisplayName.value, 
                showFullName: showFullLegalName.value, 
                showAboutMe: showAboutMe.value, 
                showWorkingExperience: showWorkingExp.value, 
                showOrganization: showOrganization.value
            }

            axios.put( '/user/profile', p
                ).then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Privacy setting is updated."
                        })

                        getProfile()

                    }  else {
                        func.addLog('preference', 'updPrivacy', res)

                            if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('updPrivacy', 'updProfile - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        }

        const downloadEntityKyc = async (url) => {
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = 'entity_kyc.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const downloadNricKyc = async (url) => {
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = 'nric_kyc.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const downloadPassportKyc = async (url) => {
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = 'passport_kyc.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const uplDrawSign = () => {
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = imgDS.value

            canvasDS.value.width = imageElement.naturalWidth
            canvasDS.value.height = imageElement.naturalHeight

            canvasDSContext.value = canvasDS.value.getContext('2d')
            canvasDSContext.value.filter = "brightness(150%)"
            canvasDSContext.value.filter = "contrast(150%)"
            
            canvasDSContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasDSContext.value.getImageData(0, 0, canvasDS.value.width, canvasDS.value.height)

            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]
                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0

                }

            }

            // Crop tranparent image
            canvasDSContext.value.putImageData(imageData, 0, 0)
            canvasDSContext.value = canvasDS.value.getContext('2d')
            const imageData2 = canvasDSContext.value.getImageData(0, 0, canvasDS.value.width, canvasDS.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasDS.value.width
                    y = ~~((i / 4) / canvasDS.value.width)
                
                    if (bound.top === null) {
                        bound.top = y

                    }
                    
                    if (bound.left === null) {
                        bound.left = x

                    } else if (x < bound.left) {
                        bound.left = x

                    }
                    
                    if (bound.right === null) {
                        bound.right = x

                    } else if (bound.right < x) {
                        bound.right = x

                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y

                    } else if (bound.bottom < y) {
                        bound.bottom = y

                    }

                }

            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasDSContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasDSContext.value.canvas.width = trimWidth
            canvasDSContext.value.canvas.height = trimHeight
            canvasDSContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasDS.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/signature', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('signature')
                            vueCanvasDS.value.reset()

                        } else {
                            func.addLog('preference', 'uplDrawSign', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('preference', 'uplDrawSign - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })
            })

        }

        const uplDrawInit = () => {
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = imgDI.value

            canvasDI.value.width = imageElement.naturalWidth
            canvasDI.value.height = imageElement.naturalHeight

            canvasDIContext.value = canvasDI.value.getContext('2d')
            canvasDIContext.value.filter = "brightness(150%)"
            canvasDIContext.value.filter = "contrast(150%)"
            
            canvasDIContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasDIContext.value.getImageData(0, 0, canvasDI.value.width, canvasDI.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]
                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0

                }

            }

            // Crop tranparent image
            canvasDIContext.value.putImageData(imageData, 0, 0)
            canvasDIContext.value = canvasDI.value.getContext('2d')
            const imageData2 = canvasDIContext.value.getImageData(0, 0, canvasDI.value.width, canvasDI.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasDI.value.width
                    y = ~~((i / 4) / canvasDI.value.width)
                
                    if (bound.top === null) {
                        bound.top = y

                    }
                    
                    if (bound.left === null) {
                        bound.left = x

                    } else if (x < bound.left) {
                        bound.left = x

                    }
                    
                    if (bound.right === null) {
                        bound.right = x

                    } else if (bound.right < x) {
                        bound.right = x

                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y

                    } else if (bound.bottom < y) {
                        bound.bottom = y

                    }

                }

            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasDIContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasDIContext.value.canvas.width = trimWidth
            canvasDIContext.value.canvas.height = trimHeight
            canvasDIContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasDI.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/initial', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('initial')
                            vueCanvasDI.value.reset()

                        } else {
                            func.addLog('preference', 'uplDrawInit', res)

                             if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('preference', 'uplDrawInit - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })
            })

        }

        const convertSigTextToImg = () => {
            sigContext.value.clearRect(0, 0, 300, 80)
            sigContext.value.fillStyle = sigFontColor.value
            sigContext.value.font = sigFontSize.value + " " + sigFontFamily.value
            sigContext.value.fillText(sigText.value, 10, 50)

        }

        const uplSigText = () => {

            // Crop text to image
            const imageData2 = sigContext.value.getImageData(0, 0, sigCanvas.value.width, sigCanvas.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % sigCanvas.value.width
                    y = ~~((i / 4) / sigCanvas.value.width)
                
                    if (bound.top === null) {
                        bound.top = y

                    }
                    
                    if (bound.left === null) {
                        bound.left = x

                    } else if (x < bound.left) {
                        bound.left = x

                    }
                    
                    if (bound.right === null) {
                        bound.right = x

                    } else if (bound.right < x) {
                        bound.right = x

                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y

                    } else if (bound.bottom < y) {
                        bound.bottom = y

                    }

                }

            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = sigContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            sigContext.value.canvas.width = trimWidth
            sigContext.value.canvas.height = trimHeight
            sigContext.value.putImageData(trimmed, 0, 0)

            const canvas = sigCanvas.value
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/signature', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('signature')
                            sigText.value = ''
                            sigCanvas.value.width = 300
                            sigCanvas.value.height = 80

                        } else {
                            func.addLog('preference', 'uplSignText', res)

                             if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('preference', 'uplSignText - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })

            })

        }

        const convertIniTextToImg = () => {
            iniContext.value.clearRect(0, 0, 300, 80)
            iniContext.value.fillStyle = iniFontColor.value
            iniContext.value.font = iniFontSize.value + " " + iniFontFamily.value
            iniContext.value.fillText(iniText.value, 10, 50)

        }

        const uplIniText = () => {
            // Crop text to image
            const imageData2 = iniContext.value.getImageData(0, 0, iniCanvas.value.width, iniCanvas.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % iniCanvas.value.width
                    y = ~~((i / 4) / iniCanvas.value.width)
                
                    if (bound.top === null) {
                        bound.top = y

                    }
                    
                    if (bound.left === null) {
                        bound.left = x

                    } else if (x < bound.left) {
                        bound.left = x

                    }
                    
                    if (bound.right === null) {
                        bound.right = x

                    } else if (bound.right < x) {
                        bound.right = x

                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y

                    } else if (bound.bottom < y) {
                        bound.bottom = y

                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = iniContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            iniContext.value.canvas.width = trimWidth
            iniContext.value.canvas.height = trimHeight
            iniContext.value.putImageData(trimmed, 0, 0)

            const canvas = iniCanvas.value
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/initial', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('initial')
                            iniText.value = ''
                            iniCanvas.value.width = 300
                            iniCanvas.value.height = 80

                        } else {
                            func.addLog('preference', 'uplIniText', res)

                             if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('preference', 'uplIniText - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })

            })

        }

        const addSignatureStart = async (file) => {
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = canvasSContextSrc.value

            canvasS.value.width = imageElement.naturalWidth
            canvasS.value.height = imageElement.naturalHeight

            canvasSContext.value = canvasS.value.getContext('2d')
            canvasSContext.value.filter = "brightness(150%)"
            canvasSContext.value.filter = "contrast(150%)"
            
            canvasSContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasSContext.value.getImageData(0, 0, canvasS.value.width, canvasS.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]
                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0

                }

            }

            // Crop tranparent image
            canvasSContext.value.putImageData(imageData, 0, 0)
            canvasSContext.value = canvasS.value.getContext('2d')
            const imageData2 = canvasSContext.value.getImageData(0, 0, canvasS.value.width, canvasS.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasS.value.width
                    y = ~~((i / 4) / canvasS.value.width)
                
                    if (bound.top === null) {
                        bound.top = y

                    }
                    
                    if (bound.left === null) {
                        bound.left = x

                    } else if (x < bound.left) {
                        bound.left = x

                    }
                    
                    if (bound.right === null) {
                        bound.right = x

                    } else if (bound.right < x) {
                        bound.right = x

                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y

                    } else if (bound.bottom < y) {
                        bound.bottom = y

                    }

                }

            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasSContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasSContext.value.canvas.width = trimWidth
            canvasSContext.value.canvas.height = trimHeight
            canvasSContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasS.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/signature', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('signature')

                            setTimeout(() => {
                                isUploadingSig.value = false
                            }, 500)
                            
                        } else {
                            func.addLog('preference', 'addSignatureStart', res)

                             if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('preference', 'addSignatureStart - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })

            })

        }

        const addInitialStart = async (file) => {

            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = canvasIContextSrc.value

            canvasI.value.width = imageElement.naturalWidth
            canvasI.value.height = imageElement.naturalHeight

            canvasIContext.value = canvasI.value.getContext('2d')
            canvasIContext.value.filter = "brightness(150%)"
            canvasIContext.value.filter = "contrast(150%)"
            canvasIContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasIContext.value.getImageData(0, 0, canvasI.value.width, canvasI.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]
                if (Math.abs(red - backgroundColor.red) < threshold &&
                    Math.abs(green - backgroundColor.green) < threshold &&
                    Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0

                }

            }

            // Crop tranparent image
            canvasIContext.value.putImageData(imageData, 0, 0)
            canvasIContext.value = canvasI.value.getContext('2d')
            const imageData2 = canvasIContext.value.getImageData(0, 0, canvasI.value.width, canvasI.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasI.value.width
                    y = ~~((i / 4) / canvasI.value.width)
                
                    if (bound.top === null) {
                        bound.top = y

                    }
                    
                    if (bound.left === null) {
                        bound.left = x

                    } else if (x < bound.left) {
                        bound.left = x

                    }
                    
                    if (bound.right === null) {
                        bound.right = x

                    } else if (bound.right < x) {
                        bound.right = x

                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y

                    } else if (bound.bottom < y) {
                        bound.bottom = y

                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasIContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasIContext.value.canvas.width = trimWidth
            canvasIContext.value.canvas.height = trimHeight
            canvasIContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasI.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/initial', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('initial')

                            setTimeout(() => {
                                isUploadingIni.value = false
                            }, 500)

                        } else {
                            func.addLog('preference', 'addInitialStart', res)

                             if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('preference', 'addInitialStart - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })

            })
            
        }

        const addStampStart = async (file) => {

            // Remove image background to transparent
            const backgroundColor = { red: 255, green: 255, blue: 255 }
            const threshold = 10

            const imageElement = new Image()
            imageElement.src = canvasStContextSrc.value

            canvasSt.value.width = imageElement.naturalWidth
            canvasSt.value.height = imageElement.naturalHeight

            canvasStContext.value = canvasSt.value.getContext('2d')
            canvasStContext.value.filter = "brightness(150%)"
            canvasStContext.value.filter = "contrast(150%)"
            
            canvasStContext.value.drawImage(imageElement, 0, 0)

            const imageData = canvasStContext.value.getImageData(0, 0, canvasSt.value.width, canvasSt.value.height)
            for (var i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i]
                const green = imageData.data[i + 1]
                const blue = imageData.data[i + 2]

                if (Math.abs(red - backgroundColor.red) < threshold &&
                Math.abs(green - backgroundColor.green) < threshold &&
                Math.abs(blue - backgroundColor.blue) < threshold) {
                    imageData.data[i + 3] = 0

                }

            }

            // Crop tranparent image
            canvasStContext.value.putImageData(imageData, 0, 0)
            canvasStContext.value = canvasSt.value.getContext('2d')
            const imageData2 = canvasStContext.value.getImageData(0, 0, canvasSt.value.width, canvasSt.value.height)
            let len = imageData2.data.length
            let x = 0
            let y = 0
            const bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            }

            for (var i = 0; i < len; i += 4) {
                if (imageData2.data[i+3] !== 0) {
                    x = (i / 4) % canvasSt.value.width
                    y = ~~((i / 4) / canvasSt.value.width)
                
                    if (bound.top === null) {
                        bound.top = y

                    }
                    
                    if (bound.left === null) {
                        bound.left = x

                    } else if (x < bound.left) {
                        bound.left = x

                    }
                    
                    if (bound.right === null) {
                        bound.right = x

                    } else if (bound.right < x) {
                        bound.right = x

                    }
                    
                    if (bound.bottom === null) {
                        bound.bottom = y

                    } else if (bound.bottom < y) {
                        bound.bottom = y

                    }
                }
            }

            let trimHeight = bound.bottom - bound.top
            let trimWidth = bound.right - bound.left
            let trimmed = canvasStContext.value.getImageData(bound.left, bound.top, trimWidth, trimHeight)

            canvasStContext.value.canvas.width = trimWidth
            canvasStContext.value.canvas.height = trimHeight
            canvasStContext.value.putImageData(trimmed, 0, 0)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: 'include',
            }

            const canvas = canvasSt.value
            canvas.toBlob((blob) => {

                let formData = new FormData()
                formData.append('file', blob)

                axios.post( axios.defaults.baseURL + 'user/signature/stamp', formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            getHistoryList('stamp')

                            setTimeout(() => {
                                isUploadingSta.value = false
                            }, 500)
                            
                        } else {
                            func.addLog('preference', 'addStampStart', res)

                             if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                    
                    })
                    .catch((error) => {
                        func.addLog('preference', 'addStampStart - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })

            })

        }

        const setDob = (date) => {
            dob.value = func.convDateTimeFormat(date, 'date')

        }

        const setPassportExpiry = (date) => {
            passportExpiry.value = func.convDateTimeFormat(date, 'date')

        }

        const setPassportExpiry2 = (date) => {
            passportExpiry2.value = func.convDateTimeFormat(date, 'date')

        }

        const getKYCConfig = () => {
            let kycUrl = axios.defaults.baseURL + "user/kyc/upload/" + kyc.value

            kycConfig.value = {
                "url": kycUrl, 
                "process": { 
                    "method": "POST", 
                    "withCredentials": true, 
                    "timeout": 20000 
                }
            }

        }

        const getLogo = () => {
            axios.get('/entity/logo/' + user.value.jEntity.entityId, { responseType: 'arraybuffer' })
            .then((res) => {

                const b64 = btoa(String.fromCharCode(...new Uint8Array(res.data)))
                // const imgData = 'data:' + res.headers['content-type'] + ';base64,' + b64
                logoSrc.value = b64

            })
            .catch((error) => {
                func.addLog('preference', 'getLogo - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            
            })

        }

        const getProfile = () => {
            arrNonImgSign.value = []
            arrNonImgPreview.value = []

            axios.get('/user/profile')
                .then((res) => {
                    
                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        // console.info('getProfile', JSON.stringify(res.data.data))

                        // for non-image signature
                        arrNonImgSign.value.push('fullLegalName')
                        arrNonImgPreview.value.push(res.data.data.fullName)

                        // console.info('getProfile dob', res.data.data.dob)
                        user.value = res.data.data
                        email.value = res.data.data.userId
                        fullLegalName.value = res.data.data.fullName
                        displayName.value = res.data.data.displayName
                        newDisplayName.value = res.data.data.displayName
                        nric.value = res.data.data.jIdentity.nric
                        dob.value = res.data.data.dob
                        passport.value = res.data.data.jIdentity.passport
                        passportExpiry.value = res.data.data.jIdentity.passportExpiry
                        passportIssueCountryId.value = res.data.data.jIdentity.passportIssueCountryId
                        title.value = res.data.data.title
                        gender.value = res.data.data.gender
                        gmt.value = res.data.data.gmt
                        address.value = res.data.data.jAddress.address
                        postcode.value = res.data.data.jAddress.postcode
                        state.value = res.data.data.jAddress.state
                        countryId.value = res.data.data.jAddress.countryId
                        avatar.value = res.data.data.jAvatar
                        avatarSrc.value = res.data.data.jAvatar.imgBase64
                        dateCreate.value = res.data.data.dateCreate
                        dateLastLogin.value = res.data.data.dateLastLogin
                        plan.value = res.data.data.plan
                        aboutMe.value = res.data.data.aboutMe
                        workingExp.value = res.data.data.workingExperience
                        facebook.value = res.data.data.jSocial.facebook
                        twitter.value = res.data.data.jSocial.twitter
                        discord.value = res.data.data.jSocial.discord
                        userKycStatus.value = res.data.data.kycStatus
                        userKycRemark.value = res.data.data.kycRemark
                        entityKycStatus.value = res.data.data.jEntity.kycStatus
                        entityKycRemark.value = res.data.data.jEntity.kycRemark

                        objTeam.value = res.data.data.jTeam
                        entityDesignation.value = res.data.data.jEntity.designation

                        showDisplayName.value = res.data.data.showDisplayName
                        showFullLegalName.value = res.data.data.showFullName
                        showAboutMe.value = res.data.data.showAboutMe
                        showWorkingExp.value = res.data.data.showWorkingExperience
                        showOrganization.value = res.data.data.showOrganization

                        if (fullLegalName.value === '' || fullLegalName.value === undefined || 
                            gender.value === '' || gender.value === undefined ||
                            dob.value === '' || dob.value === undefined ||
                            address.value === '' || address.value === undefined ||
                            countryId.value === '' || countryId.value === undefined || 
                            ((nric.value === '' || nric.value == undefined) && (passport.value === '' || passport.value === undefined)) || 
                            (passport.value !== '' && passport.value !== undefined && (passportExpiry.value === '' || passportExpiry.value === undefined)) ||
                            (passport.value !== '' && passport.value !== undefined && (passportIssueCountryId.value === '' || passportIssueCountryId.value === undefined))){
                            
                            allowKyc.value = false

                        } else {
                            allowKyc.value = true

                        }

                        for (var i = 0; i < objTeam.value.length; i++) {
                            if (objTeam.value[i].teamId === '1') {
                                isAdmin.value = true

                            }

                        }

                        getAvatar()

                        // yntemp getLogo()

                    } else {
                        func.addLog('preference', 'getProfile', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'getProfile - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const getAvatar = () => {
            axios.get( '/user/avatar/' + getUserId.value, { responseType: 'arraybuffer' }
                ).then((res) => {
                    const b64 = btoa(String.fromCharCode(...new Uint8Array(res.data)))
                    const imgData = 'data:' + res.headers['content-type'] + ';base64,' + b64
                    avatar.value.imgBase64 = imgData
                    // console.info('- - - imgAvatar', avatarSrc.value)
                })
                .catch((error) => {
                    func.addLog('comp: imgavatar', 'onMounted - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        }

        const updProfile = () => {

            nric.value = nric.value.replaceAll('-', '')

            if (displayName.value === null || displayName.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Name is required."
                })

            } else if (passport.value !== '' && passportExpiry.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Passport expiry is required."
                })

            } else if (passport.value !== '' && passportIssueCountryId.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Passport country is required."
                })

            } else {
                dob.value = dob.value.replaceAll('-', '')
                passportExpiry.value = passportExpiry.value.replaceAll('-', '')
                const p = {
                    fullName: fullLegalName.value,
                    displayName: displayName.value,
                    nric: nric.value,
                    dob: dob.value,
                    passport: passport.value, 
                    passportExpiry: passportExpiry.value, 
                    passportIssueCountryId: passportIssueCountryId.value,
                    title: title.value,
                    gender: gender.value,
                    gmt: gmt.value,
                    address: address.value, 
                    postcode: postcode.value, 
                    state: state.value, 
                    countryId: countryId.value, 
                    aboutMe: aboutMe.value,
                    workingExperience: workingExp.value,
                    facebook: facebook.value, 
                    twitter: twitter.value, 
                    discord: discord.value
                }

                if (uiStructure.value === 'PERSONAL') {
                    p.designation = entityDesignation.value
                }

                // console.info('updProfile p', JSON.stringify(p))
                axios.put('/user/profile', p)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            newDisplayName.value = computed({
                                get: () => store.getDisplayName.value,
                                set: store.updDisplayName(displayName.value),
                            })
                            newDisplayName.value = store.getDisplayName.value 

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Personal information is updated."
                            })

                            getProfile()

                        }  else {
                            func.addLog('preference', 'updProfile', res)

                             if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    })
                    .catch((error) => {
                        func.addLog('preference', 'updProfile - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                        
                    })
            }
        }

        const updNewPassport = () => {
            if (passport2.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Passport is required."
                })

            } else if (passportExpiry2.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Passport expiry is required."
                })

            } else if (passportIssueCountryId2.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Passport country is required."
                })

            } else {
                passportExpiry2.value = passportExpiry2.value.replaceAll('-', '')
                const p = {
                    passport: passport2.value, 
                    passportExpiry: passportExpiry2.value, 
                    passportIssueCountryId: passportIssueCountryId2.value
                }
                // another API to upload new passport info?

                axios.put('/user/profile/pending/passport', p)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "New passport information is submitted."
                            })

                            hasKycPassportUpd.value = true
                            chkKycPassportUpdate()
                            getProfile()

                        }  else {
                            func.addLog('preference', 'updNewPassport', res)
                            hasKycPassportUpd.value = false
                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    })
                    .catch((error) => {
                        func.addLog('preference', 'updNewPassport - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                        
                    })

            }
        }

        const delNewPassport = () => {
            axios.delete('/user/profile/pending/passport')
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        hasKycPassportUpd.value = false
                        objKycPassportUpd.value = {}

                        passport2.value = ''
                        passportExpiry2.value = ''
                        passportIssueCountryId2.value = ''

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Renew passport request is removed."
                        })

                        

                    }  else {
                        func.addLog('preference', 'delNewPassport', res)

                            if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'delNewPassport - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })

        }

        const updKycInfo = () => {
            if (fullLegalName2.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Full legal name is required."
                })

            } else if (gender2.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Gender is required."
                })

            } else if (nric2.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "NRIC is required."
                })

             } else if (address2.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Street address is required."
                })

             } else if (countryId2.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Country is required."
                })

            } else {
                const p = {
                    fullName: fullLegalName2.value, 
                    gender: gender2.value, 
                    nric: nric2.value,
                    address: address2.value,
                    countryId: countryId2.value
                }
                // another API to upload new passport info?

                axios.put('/user/profile/pending', p)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            hasKycProfileUpd.value = true
                            chkKycProfileUpdate()

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "New passport information is submitted."
                            })

                            getProfile()

                        }  else {
                            func.addLog('preference', 'updKycInfo', res)
                            hasKycProfileUpd.value = false
                             if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    })
                    .catch((error) => {
                        func.addLog('preference', 'updKycInfo - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                        
                    })

            }
        }

        const delKycInfo = () => {
            axios.delete('/user/profile/pending')
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        hasKycProfileUpd.value = false
                        objKycProfileUpd.value = {}

                        fullLegalName2.value = ''
                        gender2.value = ''
                        nric2.value = ''
                        address2.value = ''
                        countryId2.value = ''

                        
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Edit personal information request is removed."
                        })

                    }  else {
                        func.addLog('preference', 'delKycInfo', res)

                            if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'delKycInfo - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })

        }

        const getEntity = () => {

            axios.get('/entity/profile')
                .then((res) => {
              
                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        // console.info('getEntity', JSON.stringify(res.data.data))
                        objEntity.value = res.data.data
                        isEntityVerified.value = res.data.data.isVerified
                        entityName.value = res.data.data.entityName
                        entityRegID.value = res.data.data.registrationId
                        entityShortName.value = res.data.data.shortName
                        uiStructure.value = res.data.data.structure
                        entityCountry.value = res.data.data.jAddress.countryId
                        entityTotalMember.value = res.data.data.totalMember
                        entityUrl.value = res.data.data.jSocial.website
                        entityFacebook.value = res.data.data.jSocial.facebook
                        entityTwitter.value = res.data.data.jSocial.twitter
                        entityDiscord.value = res.data.data.jSocial.discord

                        cameraGap.value = res.data.data.jLogo.height/2-16 > 32 ? (res.data.data.jLogo.height/2-16) : 0

                        if (res.data.data.entityName === '' || res.data.data.entityName === undefined || 
                            res.data.data.registrationId === '' || res.data.data.registrationId === undefined || 
                            res.data.data.jAddress.countryId === '' || res.data.data.jAddress.countryId === undefined || 
                            res.data.data.jSocial.website === '' || res.data.data.jSocial.website === undefined){
                            allowEntityKyc.value = false

                        } else {
                            allowEntityKyc.value = true

                        }

                    } else {
                        func.addLog('preference', 'getEntity', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('preference', 'getEntity - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const updEntity = () => {
            const p = {
                entityName: entityName.value,
                registrationId: entityRegID.value,
                countryId: entityCountry.value,
                website: entityUrl.value,
                facebook: entityFacebook.value,
                twitter: entityTwitter.value,
                discord: entityDiscord.value
            }

            axios.put('/entity/profile', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Organization informaion is updated."
                        })

                        getEntity()
                        getEntityKycList()

                    }  else {
                        func.addLog('preference', 'updEntity', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'updEntity - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })

        }

        const updDesignation = () => {
            const p = { 
                designation: entityDesignation.value
             }

             axios.put('/user/profile', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Designation is updated."
                        })

                    }  else {

                        func.addLog('preference', 'updDesignation', res)
                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('preference', 'updDesignation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const updStructure = (newStructure) => {
            axios.put('/entity/structure/' + newStructure, {})
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The structure has been changed. Please login again to see the updates."
                        })

                    } else {
                        func.addLog('preference', 'updStructure', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('preference', 'updStructure - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const updPassword = () => {

            if (password.value !== password2.value) {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Password and reconfirm password do not match. '
                })

            } else {
                const p = {
                    currentPassword: oldPassword.value,
                    password: password.value
                }

                axios.put('/user/password', p)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Password is changed."
                            })

                        } else {
                            func.addLog('preference', 'updPassword', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    })
                    .catch((error) => {
                        func.addLog('preference', 'updPassword - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })

            }

        }

        const getCountryOpt = () => {
            axios.get('/reference/country')
                .then((res) => {
                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        countryOpt.value = res.data.data

                    } else {
                        func.addLog('preference', 'getCountryOpt', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('preference', 'getCpuntryOpt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
                })

        }

        const getGmtOpt = () => {
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        gmtOpt.value = res.data.data

                    } else {
                        func.addLog('preference', 'getGmtOpt', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('preference', 'getGmtOpt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getTitleOpt = () => {
            // Title list
            axios.get('/reference/title')
            .then((res) => {

                if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    titleOpt.value = res.data.data

                } else {
                    func.addLog('preference', 'getTitleOpt', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }
            })
            .catch((error) => {
                func.addLog('preference', 'getTitleOpt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const getKyc = (error, file) => {
            /* console.info('addfile: err', error)
            console.info('addfile: file', file)
            console.info('addfile: file.filename', file.filename)
            console.info('addfile: file.fileSize', file.fileSize)
            console.info('addfile: file.file', file.file)
            console.info('addfile: file.source', file.source)
            console.info('addfile: file.status', file.status)
            console.info('addfile: file.transferId', file.transferId) */
        }

        const uplKycError = (error) => {
            func.addLog('preference', 'uplKycError - NRIC', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

        }

        const getKyc2 = (error, file) => {
            /* console.info('addfile: err', error)
            console.info('addfile: file', file)
            console.info('addfile: file.filename', file.filename)
            console.info('addfile: file.fileSize', file.fileSize)
            console.info('addfile: file.file', file.file)
            console.info('addfile: file.source', file.source)
            console.info('addfile: file.status', file.status)
            console.info('addfile: file.transferId', file.transferId) */
        }

        const uplKyc2Error = (error) => {
            func.addLog('preference', 'uplKycError - Passport', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

        }

        const onprocessKyc2 = (err, file) => {
            if (err === null) {
                getKycList()
                pondKyc2.value.removeFile(file) // Remove preview image after uploaded complete

            }

        }

        const onprocessKyc = (err, file) => {
            if (err === null) {
                getKycList()
                pondKyc.value.removeFile(file) // Remove preview image after uploaded complete

            }

        }

        const getKycList = () => {
            arrKycNric.value = []
            arrKycPassport.value = []

            axios.get( '/user/kyc/list/all')
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        if (res.data.data.jKYC.length > 0) {
                            for(var i = 0; i < res.data.data.jKYC.length; i++) {
                                if (res.data.data.jKYC[i].type === 'passport') {
                                    arrKycPassport.value.push(res.data.data.jKYC[i])
                                } else {
                                    arrKycNric.value.push(res.data.data.jKYC[i])
                                }
                            }
                        }

                    } else {
                        func.addLog('preference', 'getKycList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'getKycList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const delKyc = (id) => {
            axios.delete( '/user/kyc/' + id)
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        getKycList()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Image is removed."
                        })

                    } else {
                        func.addLog('preference', 'delKyc', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'delKyc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                   
                })

        }

        const uplAvatarError = (error) => {
            func.addLog('preference', 'uplAvatarError', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

        }

        const onprocessAvatar = (err, file) => {
            if (err === null) {
                getProfile()

            }

        }

        const uplEntityError = (error) => {
            func.addLog('preference', 'uplEntityError', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

        }

        const onprocessEntity = (err, file) => {
        }

        const uplEntityKycError = (error) => {
            func.addLog('preference', 'uplEntityKycError', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

        }

        const onprocessEntityKyc = (err, file) => {
            if (err === null) {
                getEntityKycList()
                pondK.value.removeFile(file) // Remove preview image after uploaded complete

            }

        }

        const getEntityKycList = () => {
            axios.get( '/entity/kyc/list')
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        arrEntityKyc.value = res.data.data.jKYC

                    } else {
                        func.addLog('preference', 'getEntityKycList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'getEntityKycList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const delEntityKyc = (id) => {
            axios.delete( '/entity/kyc/' + id)
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        getEntityKycList()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Image is removed."
                        })

                    } else {
                        func.addLog('preference', 'delEntityKyc', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'delEntityKyc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const resendActivation = () => {
            const p = {
                username: newEmail.value,
                frontendUrl: '/dashboard'
            }

            axios.post('/auth/signup/resend', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "An activation email has sent to you, please click the link to activate it."
                            })

                    } else {
                        func.addLog('preference', 'resendActivation', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('preference', 'resendActivation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
                })

        }

        const uplInitialError = (error) => {
            func.addLog('preference', 'uplInitialError', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
        }

        const onprocessInitial = (err, file) => {
            if (err === null) {
                pondI.value.removeFile(file) // Remove preview image after uploaded complete
                getHistoryList('initial')
                pondI.value.removeFile(file) // Remove preview image after uploaded complete

            }
        }

        const uplSignatureError = (error) => {
            func.addLog('preference', 'uplSignatureError', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

        }

        const onprocessSignature = (err, file) => {
            if (err === null) {
                pondS.value.removeFile(file) // Remove preview image after uploaded complete
                getHistoryList('signature')
                pondS.value.removeFile(file) // Remove preview image after uploaded complete

            }

        }

        const uplStampError = (error) => {
            func.addLog('preference', 'uplStampError', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

        }

        const onprocessStamp = (err, file) => {
            if (err === null) {
                pondSt.value.removeFile(file) // Remove preview image after uploaded complete
                getHistoryList('stamp')

            }

        }

        const getHistoryList = async (listOpt) => {
            if (listOpt === 'all') {

                arrIni.value = []
                arrSta.value = []
                arrSig.value = []

                axios.get( '/user/signature/list/all')
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        // console.info('signature list', JSON.stringify(res.data.data))

                        let objData = []
                        objData = res.data.data

                        let len = objData.length
                        for (var i = 0; i < len; i++) {
                            if (objData[i].type === 'initial') {
                                arrIni.value.push(objData[i])

                            } else if (objData[i].type === 'stamp') {
                                arrSta.value.push(objData[i])
                                if (arrSta.value.length > 0) {
                                    selectStamp(arrSta.value[0])

                                }

                            } else {
                                arrSig.value.push(objData[i])
                                if (arrSig.value.length > 0) {
                                    selectSignature(arrSig.value[0])

                                }

                            }

                        }

                    } else {
                        func.addLog('preference', 'getHistoryList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('preference', 'getHistoryList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

            } else {
                axios.get( '/user/signature/list/' + listOpt)
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        if (listOpt === 'initial') {
                            arrIni.value = res.data.data

                        } else if (listOpt === 'stamp') {
                            arrSta.value = res.data.data
                            if (arrSta.value.length > 0) {
                                selectStamp(arrSta.value[0])

                            }

                        } else {
                            arrSig.value = res.data.data

                            if (res.data.data.length > 0) {
                                selectSignature(res.data.data[0])

                            }

                        }

                    } else {
                        func.addLog('preference', 'getHistoryList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('preference', 'getHistoryList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

            }

        }

        const delSignature = (id, opt) => {
            // console.info('del', id, opt)
            axios.delete( '/user/signature/' + id)
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        getHistoryList(opt)
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signature is removed."
                        })

                    } else {
                        func.addLog('preference', 'delSignature', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'delSignature - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })

        }

        const validatePassword = () => {
            setTimeout(() => {
                isValidPassword.value = true
            }, 1000)

        }

        const sendUpgradeEmail = () => {
            // send email
            upgradeStep.value = 2

        }

        const verifyUpgradeTac = () => {
            setTimeout(() => {
                isValidTac.value = true
                upgradeStep.value = 3
            }, 1000)

        }

        const makePayment = () => {
            router.push({ path: '/auth/preference/upgrade/' + route.params.packageId + '/payment'})

        }

        const valPassword = () => {
            pwd_length.value = password.value.length >= 11
            pwd_number.value = /\d/.test(password.value)
            pwd_lowercase.value = /[a-z]/.test(password.value)
            pwd_uppercase.value = /[A-Z]/.test(password.value)
            pwd_special.value = /[!@#\$%\^\&*\)\(+=._-]/.test(password.value)

            if (pwd_length.value && pwd_number.value && pwd_lowercase.value && pwd_uppercase.value && pwd_special.value) {
                passwordFormat.value = true
                valPasswordMatch()

            } else {
                passwordFormat.value = false

            }

        }

        const valPasswordMatch = () => {
            if (password.value === password2.value) {
                passwordMatch.value = true

            } else {
                passwordMatch.value = false

            }

        }

        const chkKycPassportUpdate = () => {
            axios.get( '/user/profile/pending/passport')
                .then((res) => {

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        hasKycPassportUpd.value = true
                        objKycPassportUpd.value = res.data.data

                        passport2.value = res.data.data.passport
                        passportExpiry2.value = res.data.data.passportExpiry
                        passportIssueCountryId2.value = res.data.data.passportIssueCountryId

                    }
                    else
                    {
                        hasKycPassportUpd.value = false
                        objKycPassportUpd.value = {}

                        passport2.value = ''
                        passportExpiry2.value = ''
                        passportIssueCountryId2.value = ''
                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'chkKycPassportUpdate - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const chkKycProfileUpdate = () => {
            axios.get( '/user/profile/pending')
                .then((res) => {
                    // console.info('- - - chkKycProfileUpdate', JSON.stringify(res.data))

                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        hasKycProfileUpd.value = true
                        objKycProfileUpd.value = res.data.data

                        fullLegalName2.value = res.data.data.fullName
                        gender2.value = res.data.data.gender
                        nric2.value = res.data.data.nric
                        address2.value = res.data.data.address
                        countryId2.value = res.data.data.countryId

                    } 
                    else 
                    {
                        hasKycProfileUpd.value = false
                        objKycProfileUpd.value = {}

                        fullLegalName2.value = ''
                        gender2.value = ''
                        nric2.value = ''
                        address2.value = ''
                        countryId2.value = ''
                    }

                })
                .catch((error) => {
                    func.addLog('preference', 'chkKycProfileUpdate - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        onMounted(async () => {
            // hardcoded

            func.clearPrevLocalStorage()

            if (route.params.packageId === 'personal' || route.params.packageId === 'team') {
                upgradeStep.value = 1

            }

            // Payment status page returned from Online Banking
            if (route.path === '/auth/preference/upgrade/personal/payment' || route.path === '/auth/preference/upgrade/team/payment'){
                upgradeStep.value = 4
                isPaymentSuccess.value = true

                setTimeout(() => {
                    router.push({ path: '/auth/preference/bill'})
                }, 2000)

            }     
            
            // Redirect to 'Billing & Subscription' tab after payment made
            if (route.path === '/auth/preference/bill'){
                selectBillTab.value = true

            }     

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                // console.info('getUserId', getUserId.value)
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }

            }

            getProfile()
            getEntity()
            getGmtOpt()
            getCountryOpt()
            getTitleOpt()
            getHistoryList('all')
            getKycList()

            /* console.info('fullLegalName', fullLegalName.value)
            arrNonImgSign.value.push('fullLegalName')
            arrNonImgPreview.value.push(fullLegalName.value)

            setTimeout(() => {
                randKeyNonImg.value = Math.floor(Math.random() * 1000)
            }, 100) */

            if (store.getUserIsVerified.value === true || store.getUserIsVerified.value === 'true') {
                chkKycProfileUpdate()
                chkKycPassportUpdate()
            }

            for (var i = 7; i < 19; i++) {
                if (i%2 == 0) {
                    fontSizeRange.value.push(i)
                }
            }

            // console.info('onmounted isveri', store.getUserIsVerified, typeof store.getUserIsVerified.value)

            const fSign = document.querySelector('.signature')
            fSign.addEventListener('change', (event) => {
                isUploadingSig.value = true
                const selectedfile = event.target.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasSContextSrc.value = srcData

                    }
                    fileReader.readAsDataURL(imageFile)

                }

            })

            // Detect drop signature
            const fSign2 = document.querySelector('.signature')
            fSign2.addEventListener('drop', (event) => {
                isUploadingSig.value = true
                event.preventDefault()
                const selectedfile = event.dataTransfer.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasSContextSrc.value = srcData

                    }
                    fileReader.readAsDataURL(imageFile)

                }

            })

            const fInit = document.querySelector('.initial')
            fInit.addEventListener('change', (event) => {
                isUploadingIni.value = true
                const selectedfile = event.target.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasIContextSrc.value = srcData

                    }
                    fileReader.readAsDataURL(imageFile)

                }

            })

            // Detect drop initial
            const fInit2 = document.querySelector('.initial')
            fInit2.addEventListener('drop', (event) => {
                isUploadingIni.value = true
                event.preventDefault()
                const selectedfile = event.dataTransfer.files
                if (selectedfile.length > 0) {

                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasIContextSrc.value = srcData

                    }
                    fileReader.readAsDataURL(imageFile)

                }

            })

            const fSta = document.querySelector('.stamp')
            fSta.addEventListener('change', (event) => {
                isUploadingSta.value = true
                const selectedfile = event.target.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasStContextSrc.value = srcData

                    }
                    fileReader.readAsDataURL(imageFile)

                }

            })

            // Detect drop signature
            const fSta2 = document.querySelector('.stamp')
            fSta2.addEventListener('drop', (event) => {
                isUploadingSta.value = true
                event.preventDefault()
                const selectedfile = event.dataTransfer.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasStContextSrc.value = srcData

                    }
                    fileReader.readAsDataURL(imageFile)

                }

            })

            sigContext.value = sigCanvas.value.getContext('2d')
            iniContext.value = iniCanvas.value.getContext('2d')

            let objMdl1 = document.querySelector('#mdlAvatar')
            let objMdl2 = document.querySelector('#mdlUploadEntity')
            let objMdl3 = document.querySelector('#mdlUploadEntityKyc')

            objMdl1.addEventListener("hide.bs.modal", function (event) {
                avatarSrc.value = computed({
                    get: () => store.getAvatar.value,
                    set: store.updAvatar(avatar.value.imgBase64),
                })
                avatarSrc.value = store.getAvatar.value

            })

            if (route.query.ct === 'admin') {
                curTab.value = 'admin'

            }

            if (route.query.ct === 'kyc') {
                curTab.value = 'kyc'

            }
        })

        // footer
        return { 
            route, router, alert, func, closeAlert, getUserStatus, getUserIsVerified, getUserId, isAdmin, allowKyc, allowEntityKyc, isEntityVerified, getStructure, 
            user, email, fullLegalName, displayName, title, gender, gmt, avatar, aboutMe, workingExp, uiStructure, gmtOpt, titleOpt, genderOpt, countryOpt, 
            uname, pass, dob, nric, address, postcode, state, countryId, passport, passportExpiry, passportIssueCountryId, facebook, twitter, discord,
            setDob, setPassportExpiry, oldPassword, password, password2, avatarSrc, newDisplayName, 
            avatarConfig, uplAvatarError, onprocessAvatar, entityConfig, uplEntityError, onprocessEntity, entityKycConfig, uplEntityKycError, onprocessEntityKyc, 
            kycConfig, kyc2Config, uplKycError, getKyc, kycOpt, kyc, uplKyc2Error, getKyc2, updProfile, updPassword, updEntity, updDesignation, 
            newEmail, resendActivation, arrKycNric, arrKycPassport, arrSig, arrIni, arrSta, showMoreSig, showMoreIni, showMoreSta, delSignature, 
            onprocessKyc, onprocessKyc2, onprocessSignature, onprocessInitial, uplSignatureError, uplInitialError, arrEntityKyc, delEntityKyc,
            dateCreate, dateLastLogin, plan, upgradeStep, upgradePassword, upgradeEmail, validatePassword, isValidPassword, sendUpgradeEmail, 
            verifyUpgradeTac, isValidTac, makePayment, isPaymentSuccess, selectBillTab, getKyc, getKycList, delKyc, getKYCConfig,
            pondKyc, pondKyc2, pondA, pondE, pondK, pondS, pondI, valPassword, pwd_length, pwd_number, pwd_lowercase, pwd_uppercase, pwd_special, 
            passwordFormat, passwordMatch, valPasswordMatch, curTab, showDisplayName, showFullLegalName, showAboutMe, showWorkingExp, showOrganization, 
            fetchFr, showSignRemark, showVerifiedEntityRemark, payInYear, entityName, entityRegID, entityShortName, entityDesignation, entityCountry, entityTotalMember, 
            entityUrl, entityFacebook, entityTwitter, entityDiscord, objEntity, updStructure, newUiStructure, isSignOn, pageView,
            sigCanvas, sigContext, sigText, sigImg, sigFontFamily, sigFontSize, sigFontColor, uplSigText, canvasS, canvasSContext, canvasSContextSrc, 
            addSignatureStart, isUploadingSig, iniCanvas, iniContext, iniText, iniImg, iniFontFamily, iniFontSize, iniFontColor, uplIniText, 
            canvasI, canvasIContext, canvasIContextSrc, addInitialStart, isUploadingIni, isUploadingSta, canvasSt, canvasStContext, canvasStContextSrc, 
            addStampStart, onprocessStamp, uplStampError, vueCanvasDS, imgDS, imgDSColor, canvasDS, canvasDSContext, uplDrawSign,
            vueCanvasDI, imgDI, imgDIColor, canvasDI, canvasDIContext, uplDrawInit, logoSrc, showComboFullLegalName, showComboDesignation, showComboOrganization, 
            showComboNric, showComboPassport, showComboDateSigned, showComboSignId, showComboSignSrc, showComboSignW, showComboSignH, showComboSignList, 
            showComboStaId, showComboStaSrc, showComboStaList, comboStructure, comboRatio, combo, changeCombo, fontFamily, fontSize, isBold, isItalic, fontSizeRange, 
            comboW, comboH, comboSignW, comboSignH, comboStaW, comboStaH, comboSignWOrig, comboSignHOrig, comboStaWOrig, comboStaHOrig, 
            selectSignature, selectStamp, showKycNric, showKycPassport, passport2, passportExpiry2, passportIssueCountryId2, setPassportExpiry2, updNewPassport, updKycInfo, 
            fullLegalName2, nric2, gender2, address2, countryId2, downloadEntityKyc, downloadNricKyc, downloadPassportKyc, entityKycStatus, entityKycRemark, userKycStatus, userKycRemark, 
            cameraGap, hasKycProfileUpd, hasKycPassportUpd, objKycPassportUpd, objKycProfileUpd, delNewPassport, delKycInfo, updPrivacy, 
            showComboPreview, randKeySign, randKeySta, arrNonImgSign, arrNonImgPreview, changeNonImgSign, randKeyNonImg, dateSignIdSample
        }
    },
    data () {
        return {
            myAvatars: [],
            myEntities: [],
            myEntityKyc: [],
            myKyc: [],
            myKyc2: [],
            mySignatures: [],
            myInitials: [],
            myStamps: []
        }
    },
    mounted () {
        const pondS = document.querySelector('.signature')
        pondS.addEventListener('FilePond:addfile', (e) => {

            if (e.detail.error === null) {
                // console.info('old mounted:  no error')
                this.$refs.pondS.removeFile(e.detail.file) 

            }

        })

        const pondI = document.querySelector('.initial')
        pondI.addEventListener('FilePond:addfile', (e) => {

            if (e.detail.error === null) {
                // console.info('old mounted:  no error')
                this.$refs.pondI.removeFile(e.detail.file) 

            }

        })

        const pondSt = document.querySelector('.stamp')
        pondSt.addEventListener('FilePond:addfile', (e) => {

            if (e.detail.error === null) {
                // console.info('old mounted:  no error')
                this.$refs.pondSt.removeFile(e.detail.file) 

            }

        })

    },
    methods: {
        handleAvatarPondInit (){
            // console.info('FilePond has initialized')
            // example of instance method call on pond reference
            this.$refs.pondA.getFiles()

        },
        handleEntityPondInit (){
            this.$refs.pondE.getFiles()

        },
        handleEntityKycPondInit (){
            this.$refs.pondK.getFiles()

        },
        handleKycUploadInit () {
            this.$refs.pondKyc.getFiles()

        },
        handleKyc2UploadInit () {
            this.$refs.pondKyc2.getFiles()

        },
        handleSignaturePondInit (){
            this.$refs.pondS.getFiles()

        },
        handleInitialPondInit (){
            this.$refs.pondI.getFiles()

        },
        handleStampPondInit (){
            this.$refs.pondSt.getFiles()

        }
    }
    
}
</script>

<style>
body {
  overflow: scroll ;
}

.divHover {
    padding: 5px; 
    background-color: var(--auth-box-bg);
}

.divHover:hover {
    background-color: var(--bgcolor-light-hover-color);
    border: 2px solid #0dcaf0;
    /* color: var(--bs-table-hover-color);
    background-color: rgba(0, 0, 0, 0.075); */
    /* box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); */
}

.divSelected {
    /* background-color: rgba(0, 0, 0, 0.075); */
    border: 2px solid #0dcaf0;
}

.tblCellHover td:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
}

.curBillBox {
    background-color: var(--auth-box-bg); 
}

.customLink {
    z-index: 10000; 
    padding-top: 50px;
    padding-bottom: 30px; 
    opacity: 0.6;
}

.customLink > svg {
    visibility: hidden;
    width: 122px;
}

.customLink:hover > svg {
    visibility: visible;
}

.customLink:hover {
    background-color: white;
}

.customLink2 {
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 10000; 
    opacity: 0.6;
}

.customLink2 > svg {
    visibility: hidden;
    width: 122px;
}

.customLink2:hover > svg {
    visibility: visible;
}

.customLink2:hover {
    background-color: white;
}

#lblCertificate {
    font-size: 14px;
    color: white;
    background-color: #333333;
    padding: 10px;
    border-radius: 5px;
    visibility: hidden;
}

#certificate:hover #lblCertificate {
    visibility: visible;
}

.packageBox {
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 25px;
}

@media (max-width: 575.98px) {
  .packageBox {
    width: 98%;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 25px;
  }
}

.filter-pill-border {
    /* border: 1px solid var(--accent-color); */
    border: 1px solid var(--bs-primary);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.lblSelected {
    font-weight: bold;
    color: var(--bs-primary);
}

.card {
    border: 1px solid var(--accent-color);
    background-color: var(--bgcolor-body-color);
}

.card-header {
    padding: 0px;
    border-bottom: 0px;
    background-color: var(--bgcolor-body-color);
}

.card-header .btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.card-header .btn:focus {
    box-shadow: 0 0 0 0;
}

.card-body {
    padding: 5px 12px;
}


@font-face {
  font-family: 'MaShanZheng';
  src: url(/fontFamily/Ma_Shan_Zheng/MaShanZheng-Regular.ttf);
}

@font-face {
  font-family: 'SourceSerifPro';
  src: url(/fontFamily/Source_Serif_Pro/SourceSerifPro-Regular.ttf);
  font-weight: normal;
font-style: normal;
}

@font-face {
  font-family: 'SourceSerifPro';
  src: url(/fontFamily/Source_Serif_Pro/SourceSerifPro-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DancingScript';
  src: url(/fontFamily/Dancing_Script/DancingScript-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'Poppins';
  src: url(/fontFamily/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'LuxuriousScript';
  src: url(/fontFamily/Luxurious_Script/LuxuriousScript-Regular.ttf);
}

@font-face {
  font-family: 'MarckScript';
  src: url(/fontFamily/Marck_Script/MarckScript-Regular.ttf);
}

@font-face {
  font-family: 'PetitFormalScript';
  src: url(/fontFamily/Petit_Formal_Script/PetitFormalScript-Regular.ttf);
}

@font-face {
  font-family: 'PinyonScript';
  src: url(/fontFamily/Pinyon_Script/PinyonScript-Regular.ttf);
}

@font-face {
  font-family: 'ClickerScript';
  src: url(/fontFamily/Clicker_Script/ClickerScript-Regular.ttf);
}

@font-face {
  font-family: 'ImperialScript';
  src: url(/fontFamily/Imperial_Script/ImperialScript-Regular.ttf);
}

@font-face {
  font-family: 'MeieScript';
  src: url(/fontFamily/Meie_Script/MeieScript-Regular.ttf);
}

@font-face {
  font-family: 'RougeScript';
  src: url(/fontFamily/Rouge_Script/RougeScript-Regular.ttf);
}

@font-face {
  font-family: 'StyleScript';
  src: url(/fontFamily/Style_Script/StyleScript-Regular.ttf);
}

</style>